import React from 'react'
import { Link } from 'react-router-dom'
import { BooleanField, FunctionField, TextField } from 'react-admin'
import { DateField, EditButton, ListView } from '../../components'
import DeploymentsButton from './DeploymentsButton'

const OrganizationsList = props => {
  const { match } = props
  return (
    <div>
      <ListView
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <TextField label="Name" source="name" />
        <TextField label="ID" source="id" />
        <BooleanField label="Support" source="enableSupport" />
        <DateField label="Joined Date" source="joinedDatetime" />
        <DeploymentsButton source="deployments" />
        <FunctionField
          render={record => (
            <EditButton component={Link} to={`${match.url}/${record.id}`} />
          )}
        />
      </ListView>
    </div>
  )
}

export default OrganizationsList
