import merge from 'lodash/merge'

const getSchemas = (config, mode = 'create') => {
  let schema = merge({}, config.createSchema)
  schema.title = undefined
  schema.description = undefined
  schema.properties['general'] = {
    type: 'object',
    title: 'General settings',
    required: ['name'],
    properties: {
      name: {
        type: 'string',
        title: 'Name'
      }
    }
  }
  if (mode === 'edit') {
    schema = merge(schema, config.editSchema)
  }

  const uiSchema = merge({}, config.uiSchema)
  uiSchema['ui:order'].splice(0, 0, 'general')
  uiSchema['general'] = {
    name: {
      'ui:options': {
        gridSize: 6,
        rightOffset: 6
      }
    }
  }
  uiSchema['environmentVariables'] = {
    'ui:options': {
      orderable: false
    },
    items: {
      name: {
        'ui:options': {
          gridSize: 6
        }
      },
      value: {
        'ui:widget': 'PasswordWidget',
        'ui:options': {
          gridSize: 6
        }
      }
    }
  }

  return { schema, uiSchema }
}

export { getSchemas }
