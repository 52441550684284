import React from 'react'
import { SimpleShowLayout, TextField, FunctionField } from 'react-admin'
import { DeleteButton } from '../../components'

const DeploymentEditGeneralTab = ({ record, version }) => (
  <SimpleShowLayout
    basePath="/deployments"
    record={record}
    resource="deployments"
    version={version}
  >
    <TextField label="Name" source="name" />
    <TextField label="Deployment ID" source="id" onClick={() => navigator.clipboard.writeText(`${record.id}`)} />
    <TextField label="Namespace" source="k8sNamespace" />
    <TextField label="Description" source="description" />
    <FunctionField
      label="Delete Deployment"
      render={record => (
        <DeleteButton
          basePath={`deployments/${record.id}`}
          record={{ id: record.id }}
          resource="deployments"
          redirect={false}
          confirmContent="Are you sure you want to remove this Deployment?"
        />
      )}
    />
  </SimpleShowLayout>
)

export default DeploymentEditGeneralTab
