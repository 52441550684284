import React, { useState } from 'react'
import get from 'lodash/get'
import ObjectField from '@rjsf/core/dist/cjs/components/fields/ObjectField'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  Switch,
  Typography,
  makeStyles
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles({
  label: {
    paddingTop: 5
  }
})

const Service = ({ formData, properties, title, uiSchema }) => {
  const enabled = formData.enabled
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)
  const handleExpanded = (e, isExpanded) => setExpanded(isExpanded)
  const handleEnabled = e => {
    e.stopPropagation()
    const enabled = e.target.checked
    setExpanded(enabled)
    properties[0].content.props.onChange(enabled)
  }

  return (
    <ExpansionPanel
      expanded={expanded}
      onChange={handleExpanded}
      square
      elevation={0}
      variant="outlined"
    >
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        {enabled !== undefined && (
          <Switch color="primary" onChange={handleEnabled} checked={enabled} />
        )}
        <Typography className={classes.label} variant="subtitle1">
          {title}
        </Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container={true} spacing={2} className={classes.root}>
          {properties
            .filter(element => element.name !== 'enabled')
            .map((element, index) => {
              let size = get(
                uiSchema,
                `${element['name']}.ui:options.gridSize`,
                12
              )
              let rightOffset = get(
                uiSchema,
                `${element['name']}.ui:options.rightOffset`,
                0
              )
              return (
                <React.Fragment key={index}>
                  <Grid item={true} xs={size}>
                    {element.content}
                  </Grid>
                  {rightOffset > 0 && <Grid item xs={rightOffset} />}
                </React.Fragment>
              )
            })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

const ServiceField = ({ uiSchema, ...rest }) => {
  uiSchema['ui:ObjectFieldTemplate'] = Service
  return <ObjectField {...rest} uiSchema={uiSchema} />
}

export default ServiceField
