import axios from 'axios'
import { stringify } from 'query-string'
import { HttpError } from 'react-admin'

const handleError = (err, isList) => {
  console.log(err.response)
  console.log(err.response.data)
  if (err.response.status === 422) {
    throw new HttpError(
      err.response.data.status,
      err.response.status,
      err.response.data.errors
    )
  }
  if (err.response.status === 401) {
    if (isList) {
      return { data: [], total: 0 }
    }
    return { data: null }
  }

  throw new HttpError(
    err.response.data.message,
    err.response.status,
    err.response.data.data
  )
}

// todo, how to better handle Keycloak CORS errors when
// fetching axios.get('/login') and being redirected to Keycloak
export const login = () => {
  let location = "/login"
  if (window.location.hostname === "localhost") {
    location = "http://localhost:5001" + location
  }

  window.location = location
}


export const logout = () => {
  let location = "/logout"
  if (window.location.hostname === "localhost") {
    location = "http://localhost:5001" + location
  }
  window.location = location
}

const getResouce = (resource, params) => {
  if (resource === 'org_users') {
    let filters = params.filter
    resource = `organizations/${filters['orgId']}/users`
    delete filters['orgId']
  } else if (resource === 'user_access_keys') {
    let filters = params.filter
    resource = `users/${filters['userId']}/access_keys`
    delete filters['userId']
  } else if (resource === 'deployment_users') {
    let filters = params.filter
    resource = `deployments/${filters['deploymentId']}/users`
    delete filters['deploymentId']
  } else if (resource === 'deployment_pipelines') {
    let filters = params.filter
    resource = `deployments/${filters['deploymentId']}/pipelines`
    delete filters['deploymentId']
  } else if (resource === 'deployment_scheduled_tasks') {
    let filters = params.filter
    resource = `deployments/${filters['deploymentId']}/scheduled_tasks`
    delete filters['deploymentId']
  } else if (resource === 'deployment_services') {
    let filters = params.filter
    resource = `deployments/${filters['deploymentId']}/services`
    delete filters['deploymentId']
  }
  return resource
}

const getList = (resource, params) => {
  let _resource = getResouce(resource, params)
  const { page, perPage } = params.pagination
  const { field, order } = params.sort
  const query = {
    page,
    page_size: perPage,
    sort_by: field,
    sort_order: order,
    filters: JSON.stringify(params.filter)
  }
  const url = `/api/v1/${_resource}?${stringify(query)}`
  return axios
    .get(url)
    .then(res => {
      return {
        data: res.data.data.results,
        total: res.data.data.count
      }
    })
    .catch(err => {
      return handleError(err, true)
    })
}

const getOne = (resource, params) => {
  const url = `/api/v1/${resource}/${params.id}`
  return axios.get(url).then(res => {
    return {
      data: res.data.data
    }
  })
}

const getManyReference = (resource, params) => {
  return getList(`${resource}/${params.id}/${params.target}`, params)
}

const create = (resource, params) => {
  const url = `/api/v1/${resource}`
  return axios
    .post(url, params ? params.data : null)
    .then(res => {
      return {
        data: res.data.data
      }
    })
    .catch(err => {
      handleError(err, false)
    })
}

const update = (resource, params) => {
  const url = `/api/v1/${resource}/${params.id}`
  return axios
    .put(url, params.data)
    .then(res => {
      return {
        data: res.data.data
      }
    })
    .catch(err => {
      handleError(err, false)
    })
}

const remove = (resource, params) => {
  const _resource = getResouce(resource, { filter: params.previousData })
  const url = `/api/v1/${_resource}/${params.id}`
  return axios
    .delete(url)
    .then(res => {
      return { data: params.previousData }
    })
    .catch(err => {
      handleError(err, false)
    })
}

export default {
  getList,
  getOne,
  getManyReference,
  create,
  update,
  delete: remove
}
