import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, Toolbar } from '@material-ui/core'
import {
  BooleanInput,
  Create,
  FormWithRedirect,
  FormDataConsumer,
  TextInput,
  SaveButton,
  RadioButtonGroupInput,
  required
} from 'react-admin'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const UserCreate = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SectionTitle title="Create User" />
      <Create {...props}>
        <FormWithRedirect
          {...props}
          initialValues={{ isSysAdmin: false }}
          render={formProps => (
            <form>
              <Grid container>
                <Grid item xs={12} lg={6} className={classes.form}>
                  <RadioButtonGroupInput
                    source="type"
                    label="User type"
                    choices={[
                      { id: 'console', name: 'Console user' },
                      { id: 'programmatic', name: 'Programmatic user' }
                    ]}
                    validate={[required()]}
                  />
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'console' && (
                        <TextInput
                          source="firstName"
                          label="First Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'console' && (
                        <TextInput
                          source="lastName"
                          label="Last Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'console' && (
                        <TextInput
                          source="email"
                          label="Email"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  {/* <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'console' && (
                        <BooleanInput
                          source="isSysAdmin"
                          label="System Administrator"
                          defaultValue={true}
                          disabled
                        />
                      )
                    }
                  </FormDataConsumer> */}
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'programmatic' && (
                        <TextInput
                          source="name"
                          label="Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type !== undefined && (
                        <BooleanInput
                          source="enabled"
                          label="Enabled"
                          defaultValue={true}
                        />
                      )
                    }
                  </FormDataConsumer>
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                <SaveButton
                  size="small"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <Button
                  className={classes.button}
                  component={Link}
                  to={props.basePath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </form>
          )}
        />
      </Create>
    </div>
  )
}

export default UserCreate
