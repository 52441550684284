import React, { useState } from 'react'
import { useStore } from 'react-redux'

import { Paper, Tab, Tabs, makeStyles } from '@material-ui/core'

import { SectionTitle, TabPanel } from '../../components'
import OrgGeneralTab from './OrgGeneralTab'
import OrgUsersTab from './OrgUsersTab'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const OrgWrapper = ({ record }) => {
  const { customer, user } = useStore().getState()
  const org = record || user.customers.filter(c => c.id === customer)[0]
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const basePath = record ? `/organizations/${org.id}` : '/organization'

  return (
    <div className={!record ? classes.root : null}>
      {!record && <SectionTitle title="Organization" />}
      <Paper className={!record ? classes.content : null}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label={'General'} value={0} />
          {(user.isSysAdmin || org.role === 'Admin') && (
            <Tab label={'Users'} value={1} />
          )}
        </Tabs>
        <TabPanel index={0} value={selectedTab}>
          <OrgGeneralTab org={org} updateStore={record ? false : true} />
        </TabPanel>
        {(user.isSysAdmin || org.role === 'Admin') && (
          <TabPanel index={1} value={selectedTab}>
            <OrgUsersTab org={org} basePath={basePath} />
          </TabPanel>
        )}
      </Paper>
    </div>
  )
}

export default OrgWrapper
