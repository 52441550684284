import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import App from './App'
import * as serviceWorker from './serviceWorker'
import {login} from "./utils/dataProvider";

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

axios.defaults.withCredentials = true
axios.interceptors.response.use(
  res => res,
  err => {
    if (err.response !== null && err.response.status === 401) {
        login()
        return
    }
    return Promise.reject(err)
  }
)

ReactDOM.render(<App />, document.getElementById('root'))
