import React from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { Divider, useMediaQuery, makeStyles } from '@material-ui/core'
import { DashboardMenuItem, MenuItemLink, getResources } from 'react-admin'
import DefaultIcon from '@material-ui/icons/ViewList'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: '0.5em',
    [theme.breakpoints.only('xs')]: {
      marginTop: 0
    },
    [theme.breakpoints.up('md')]: {
      marginTop: '1.5em'
    }
  },
  myAccountDivider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}))

const Menu = ({ className, dense, hasDashboard, onMenuClick, logout }) => {
  const classes = useStyles()
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const open = useSelector(state => state.admin.ui.sidebarOpen)
  const resources = useSelector(getResources)
  const user = useSelector(state => state.user)

  return (
    <div className={classnames(classes.main, className)}>
      <MenuItemLink
        key="myaccount"
        to={`/myaccount`}
        primaryText={user.name || user.email}
        leftIcon={<AccountCircleIcon />}
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <Divider className={classes.myAccountDivider} />
      {hasDashboard && (
        <DashboardMenuItem
          onClick={onMenuClick}
          dense={dense}
          sidebarIsOpen={open}
        />
      )}
      {resources
        .filter(resource => resource.hasList || resource.options['divider'])
        .map(resource => (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={
              (resource.options && resource.options.label) || resource.name
            }
            leftIcon={resource.icon ? <resource.icon /> : <DefaultIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        ))}
      {isXSmall && logout}
    </div>
  )
}

export default Menu
