import React from 'react'
import {
  CRUD_DELETE,
  useDelete,
  useNotify,
  useRedirect,
  useRefresh
} from 'react-admin'
import ButtonWithConfirm from '../ButtonWithConfirm'
import { makeStyles } from '@material-ui/core'
import ActionDelete from '@material-ui/icons/Delete'

const useStyles = makeStyles(theme => ({
  error: {
    color: theme.palette.error.main
  }
}))

const DeleteButton = props => {
  const {
    basePath,
    confirmTitle,
    confirmContent,
    record,
    resource,
    redirect: redirectTo = 'list'
  } = props
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const [deleteOne, { loading }] = useDelete(resource, record.id, record, {
    action: CRUD_DELETE,
    onSuccess: () => {
      notify('ra.notification.deleted', 'info', { smart_count: 1 })
      if (redirectTo) {
        redirect(redirectTo, basePath)
      }
      refresh()
    },
    onFailure: error => {
      notify(
        typeof error === 'string'
          ? error
          : error.message || 'ra.notification.http_error',
        'warning'
      )
    },
    undoable: false
  })
  const handleDelete = cb => deleteOne()

  return (
    <ButtonWithConfirm
      className={classes.error}
      onConfirm={handleDelete}
      Icon={ActionDelete}
      confirmTitle={confirmTitle}
      confirmContent={confirmContent}
      loading={loading}
    />
  )
}

export default DeleteButton
