import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormWithRedirect,
  TextInput,
  Toolbar,
  SaveButton,
  useMutation,
  useNotify,
  useQuery
} from 'react-admin'
import { Box, makeStyles } from '@material-ui/core'
import { updateUserName } from '../../redux/user'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const Spacer = () => <span style={{ flex: 1 }} />

const UserAccountEdit = () => {
  const classes = useStyles()
  const user = useSelector(state => state.user)
  const notify = useNotify()
  const dispatch = useDispatch()

  const { data: userData } = useQuery(
    {
      type: 'getOne',
      resource: `users`,
      payload: { id: user.id }
    },
    {
      onFailure: () => {
        notify('ra.notification.item_doesnt_exist', 'warning')
      }
    }
  )

  const [save, { loading }] = useMutation()
  const onSave = ({ id, firstName, lastName }) => {
    const data = { firstName, lastName }
    save(
      {
        type: 'update',
        resource: 'users',
        payload: { id, data }
      },
      {
        onSuccess: () => {
          notify('Account updated', 'info')
          dispatch(updateUserName(`${firstName} ${lastName}`))
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  if (!userData) {
    return null
  }

  return (
    <div className={classes.root}>
      <FormWithRedirect
        record={userData}
        save={onSave}
        saving={loading}
        redirect={false}
        render={formProps => (
          <form>
            <Box className={classes.form} m={2}>
              <TextInput
                label="ID"
                source="id"
                variant="standard"
                fullWidth
                disabled
              />
              <TextInput
                label="Username"
                source="email"
                variant="standard"
                fullWidth
                disabled
              />
              <TextInput
                label="First name"
                source="firstName"
                variant="standard"
                fullWidth
              />
              <TextInput
                label="Last name"
                source="lastName"
                variant="standard"
                fullWidth
              />
            </Box>
            <Toolbar className={classes.toolbar}>
              <Spacer />
              <SaveButton
                size="small"
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        )}
      />
    </div>
  )
}

export default UserAccountEdit
