import React from 'react'

const withConfig = (WrappedComponent, config) => {
  return class extends React.Component {
    render() {
      return <WrappedComponent config={config} {...this.props} />
    }
  }
}

export default withConfig
