import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { FunctionField, TextField, useQuery } from 'react-admin'
import {
  Link as MuiLink,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  makeStyles
} from '@material-ui/core'
import { InfoOutlined, OpenInNew } from '@material-ui/icons'
import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  ListActions,
  ListView
} from '../../components'
import { useAuth } from '../../utils/auth'

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: -theme.spacing(3)
  },
  infoIcon: {
    position: 'relative',
    top: 5,
    left: 5
  },
  popoverPaper: {
    padding: theme.spacing(1)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const SMenu = ({ services, deploymentId }) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  return (
    <div>
      <Button
        aria-label="+ Create"
        aria-haspopup={true}
        label="+ Create"
        disableElevation
        onClick={handleMenu}
      />
      <Popover
        id="services-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        elevation={0}
        PaperProps={{
          variant: 'outlined',
          square: true
        }}
      >
        <List dense style={{ width: 250, maxHeight: 600 }}>
          {Object.values(services).map(service => (
            <ListItem
              key={service.name}
              button
              onClick={() =>
                history.push(
                  `/deployments/${deploymentId}/services/create?type=${service.id}`
                )
              }
            >
              <ListItemText primary={service.name} />
            </ListItem>
          ))}
        </List>
      </Popover>
    </div>
  )
}

const getActions = (deploymentId, services) => {
  const Actions = props => (
    <ListActions {...props}>
      <SMenu services={services} deploymentId={deploymentId} />
    </ListActions>
  )
  return Actions
}

const DeploymentServicesTab = ({ id: deploymentId }) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [popoverRecord, setPopoverRecord] = useState(null)
  const auth = useAuth()
  const canEdit = auth.canEditDeployments()

  const { loaded, data: services } = useQuery({
    type: 'getList',
    resource: 'services',
    payload: {
      pagination: { page: 1, perPage: 10 },
      sort: { field: 'name', order: 'ASC' }
    }
  })

  const handleConnInfoPopoverOpen = record => e => {
    setPopoverRecord(record.connectionSettings)
    setAnchorEl(e.currentTarget)
  }
  const handleConnInfoPopoverClose = () => {
    setPopoverRecord(null)
    setAnchorEl(null)
  }

  if (!loaded) {
    return null
  }
  const Actions = getActions(deploymentId, services)
  return (
    <>
      <ListView
        className={classes.list}
        bulkActionButtons={false}
        basePath="/deployments"
        component="div"
        hasCreate={false}
        hasEdit={false}
        hasList
        hasShow={false}
        filter={{ deploymentId: deploymentId }}
        resource="deployment_services"
        sort={{ field: 'name', order: 'ASC' }}
        actions={canEdit ? <Actions /> : null}
      >
        <FunctionField
          label="Name"
          render={record => (
            <Link
              className={classes.link}
              to={`/deployments/${deploymentId}/services/${record.id}/show`}
            >
              {record.name}
            </Link>
          )}
        />
        <TextField label="Service ID" source="niceId" />
        <FunctionField
          label="Type"
          render={record => {
            const service = services.filter(
              service => service.id === record.serviceId
            )[0]
            return service ? <span>{service.name}</span> : null
          }}
        />
        <FunctionField
          label="Hostname"
          render={record => {
            const connSettings = Object.keys(record.connectionSettings)
            return (
              <div>
                <span>{record.connectionSettings.hostname.value}</span>
                {connSettings.length > 1 && (
                  <InfoOutlined
                    className={classes.infoIcon}
                    color="primary"
                    fontSize="small"
                    onMouseEnter={handleConnInfoPopoverOpen(record)}
                  />
                )}
              </div>
            )
          }}
        />
        <TextField label="Status" source="status" />
        <DateField label="Created At" source="addedDatetime" />
        {canEdit && (
          <FunctionField
            render={record => (
              <EditButton
                component={Link}
                to={`/deployments/${deploymentId}/services/${record.id}`}
              />
            )}
          />
        )}
        {canEdit && (
          <FunctionField
            render={record => (
              <DeleteButton
                basePath={`deployments/${deploymentId}`}
                record={{ id: record.id, deploymentId }}
                resource="deployment_services"
                redirect={false}
                confirmContent="Are you sure you want to remove this service?"
              />
            )}
          />
        )}
      </ListView>
      {popoverRecord && (
        <Popover
          id="conn-info"
          classes={{
            paper: classes.popoverPaper
          }}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          onClose={handleConnInfoPopoverClose}
          disableRestoreFocus
        >
          <List>
            {Object.values(popoverRecord)
              .filter(obj => obj.title !== 'Hostname')
              .map((obj, idx) => (
                <ListItem key={idx}>
                  <Typography variant="subtitle2">
                    {`${obj.title}:`}&nbsp;
                  </Typography>
                  <Typography variant="body2">{obj.value}</Typography>
                  {obj.isLink && (
                    <>
                      &nbsp;
                      <MuiLink href={obj.value} target="_blank">
                        <OpenInNew style={{ fontSize: 16 }} />
                      </MuiLink>
                    </>
                  )}
                </ListItem>
              ))}
          </List>
        </Popover>
      )}
    </>
  )
}

export default DeploymentServicesTab
