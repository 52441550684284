import merge from 'lodash/merge'

const types = {
  UPDATE_USER_NAME: 'UPDATE_USER_NAME',
  MERGE_USER_CUSTOMERS: 'MERGE_USER_CUSTOMERS'
}

export const mergeUserCustomers = customers => ({
  type: types.MERGE_USER_CUSTOMERS,
  payload: customers
})

export const updateUserName = name => ({
  type: types.UPDATE_USER_NAME,
  payload: name
})

export default (previousState = null, { type, payload }) => {
  if (type === types.MERGE_USER_CUSTOMERS) {
    let newUser = merge({}, previousState)
    newUser.customers.filter(c => c.id === payload.id)[0].name = payload.name
    return newUser
  } else if (type === types.UPDATE_USER_NAME) {
    let newUser = merge({}, previousState)
    newUser.name = payload
    return newUser
  }
  return previousState
}
