import { useStore } from 'react-redux'

export const isAdmin = (user, selectedCustomer) => {
  return (
    user.isSysAdmin ||
    user.customers.filter(c => c.id === selectedCustomer)[0].role === 'Admin'
  )
}

export const isEditor = (user, selectedCustomer) => {
  return (
    isAdmin(user, selectedCustomer) ||
    user.customers.filter(c => c.id === selectedCustomer)[0].role === 'Editor'
  )
}

export const isViewer = (user, selectedCustomer) => {
  return (
    isEditor(user, selectedCustomer) ||
    user.customers.filter(c => c.id === selectedCustomer)[0].role === 'Viewer'
  )
}

export const useAuth = () => {
  const { customer, user } = useStore().getState()
  const isSysAdmin = () => user.isSysAdmin
  const isCustomerAdmin = () => isAdmin(user, customer)
  const isCustomerEditor = () => isEditor(user, customer)
  const isCustomerViewer = () => isViewer(user, customer)
  const canEditOrg = () => user.isSysAdmin || isAdmin(user, customer)
  const canEditDeployments = () => user.isSysAdmin || isEditor(user, customer)

  return {
    isSysAdmin,
    isCustomerAdmin,
    isCustomerEditor,
    isCustomerViewer,
    canEditOrg,
    canEditDeployments
  }
}
