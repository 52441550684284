import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, Toolbar } from '@material-ui/core'
import {
  BooleanInput,
  Edit,
  FormWithRedirect,
  TextInput,
  SaveButton,
  required
} from 'react-admin'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const UserEdit = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SectionTitle title="Edit User" />
      <Edit {...props}>
        <FormWithRedirect
          {...props}
          render={formProps => {
            const record = formProps.record
            return (
              <form>
                <Grid container>
                  <Grid item xs={12} lg={6} className={classes.form}>
                    <TextInput
                      source="id"
                      label="ID"
                      variant="standard"
                      fullWidth
                      disabled
                    />
                    {record.type === 'console' ? (
                      <>
                        <TextInput
                          source="firstName"
                          label="First Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                        <TextInput
                          source="lastName"
                          label="Last Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                        <TextInput
                          source="email"
                          label="Email"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                        <BooleanInput
                          source="isSysAdmin"
                          label="System Administrator"
                        />
                      </>
                    ) : (
                      <TextInput
                        source="name"
                        label="Name"
                        variant="standard"
                        validate={[required()]}
                        fullWidth
                      />
                    )}
                    <BooleanInput source="enabled" label="Enabled" />
                  </Grid>
                </Grid>
                <Toolbar className={classes.toolbar}>
                  <span style={{ flex: 1 }} />
                  <SaveButton
                    size="small"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                  <Button
                    className={classes.button}
                    component={Link}
                    to={props.basePath}
                    label="Cancel"
                    variant="contained"
                    color="default"
                  />
                </Toolbar>
              </form>
            )
          }}
        />
      </Edit>
    </div>
  )
}

export default UserEdit
