const types = {
  SET_THEME: 'SET_THEME'
}

export const setTheme = theme => ({
  type: types.SET_THEME,
  payload: theme
})

export default (
  previousState = localStorage.getItem('theme') || 'dark',
  { type, payload }
) => {
  if (type === 'SET_THEME') {
    localStorage.setItem('theme', payload)
    return payload
  }
  return previousState
}
