import React, { Children, cloneElement, isValidElement } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslate } from 'react-admin'
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip
} from '@material-ui/core'
import AccountCircle from '@material-ui/icons/AccountCircle'
import InvertColors from '@material-ui/icons/InvertColors'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { Logout } from 'mdi-material-ui'
import { setTheme } from '../../redux/theme'
import {logout} from "../../utils/dataProvider";

const UserMenu = props => {
  const { children, label, icon } = props
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme)
  const translate = useTranslate()
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)

  const handleMenu = event => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  return (
    <div>
      <Tooltip title={label && translate(label, { _: label })}>
        <IconButton
          aria-label={label && translate(label, { _: label })}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup={true}
          color="inherit"
          onClick={handleMenu}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Popover
        id="user-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        elevation={0}
        PaperProps={{
          variant: 'outlined',
          square: true
        }}
      >
        {Children.map(children, menuItem =>
          isValidElement(menuItem)
            ? cloneElement(menuItem, {
                onClick: handleClose
              })
            : null
        )}
        <List dense>
          <ListItem
            button
            onClick={() =>
              dispatch(setTheme(theme === 'light' ? 'dark' : 'light'))
            }
          >
            <ListItemIcon>
              <InvertColors />
            </ListItemIcon>
            <ListItemText primary="Toggle Theme" />
          </ListItem>
          <ListItem button onClick={() => history.push('/myaccount')}>
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="My Account" />
          </ListItem>
          <ListItem button onClick={() => (logout())}>
            <ListItemIcon>
              <Logout />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Popover>
    </div>
  )
}

UserMenu.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string.isRequired,
  logout: PropTypes.element,
  icon: PropTypes.node
}

UserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />
}

export default UserMenu
