const types = {
  SET_CUSTOMER: 'SET_CUSTOMER'
}

export const setCustomer = customer => ({
  type: types.SET_CUSTOMER,
  payload: customer
})

export default (previousState = null, { type, payload }) => {
  if (type === types.SET_CUSTOMER) {
    return payload
  }
  return previousState
}
