import React from 'react'
import { Link } from 'react-router-dom'

// import { Button } from 'react-admin'
import Button from '../../components/Button'
import { TextBoxCheckOutline } from 'mdi-material-ui'

const stopPropagation = e => e.stopPropagation()

const DeploymentsButton = props => {
  const { basePath, record, ...rest } = props
  // const uri = `${basePath}/${record.id}/deployments`
  const uri = `deployments?customer_id=${record.id}`
  return (
    <Button
      component={Link}
      to={uri}
      onClick={stopPropagation}
      Icon={TextBoxCheckOutline}
      {...rest}
    />
  )
}

export default DeploymentsButton
