import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import merge from 'lodash/merge'
import {
  FormWithRedirect,
  SaveButton,
  useMutation,
  useNotify,
  useGetOne,
  useRedirect
} from 'react-admin'
import { makeStyles, Grid, Paper, Toolbar } from '@material-ui/core'
import Form from '@rjsf/material-ui'
import { Button, SectionTitle } from '../../components'
import {
  fields,
  widgets,
  ObjectFieldTemplate,
  ArrayFieldTemplate
} from './Forms'
import { getSchemas } from '../../utils/jsonSchemas'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const getRedirectPath = pathname => {
  let idx = pathname.indexOf('/services')
  return `${pathname.substring(0, idx)}/show?tab=1`
}

const getServiceIdFromUrl = location => {
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get('type')
}

const transformErrors = errors => {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = 'Required'
    }
    return error
  })
}

const DeploymentServiceCreate = ({ location, match }) => {
  const classes = useStyles()
  const serviceId = getServiceIdFromUrl(location)
  const deploymentId = match.params.id
  const redirectPath = getRedirectPath(location.pathname)
  const notify = useNotify()
  const redirect = useRedirect()
  const submitButton = useRef(null)
  const [formData, setFormData] = useState({})
  const handleChange = (elm, event) => setFormData(elm.formData)
  const [save, { loading: saving }] = useMutation()
  const onSave = ({ formData }) => {
    //ADD HANDLER KEY TO REGISTERED TASKS
    let values = merge({}, formData)

    if (
      formData.runSettings &&
      formData.runSettings.registeredTasks &&
      formData.runSettings.registeredTasks.length > 0
    ) {
      const newTaskList = formData.runSettings.registeredTasks.map(task => ({
        handler: task
      }))
      const newFormData = {
        ...formData,
        runSettings: {
          ...formData.runSettings,
          registeredTasks: newTaskList
        }
      }
      values = merge({}, newFormData)
    }

    const general = values.general
    delete values['general']
    const data = {
      name: general.name,
      serviceId,
      values
    }
    save(
      {
        type: 'create',
        resource: `deployments/${deploymentId}/services`,
        payload: { data }
      },
      {
        onSuccess: () => {
          notify('ra.notification.created', 'info')
          redirect(redirectPath)
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  const { data: addOn, loading } = useGetOne('services', serviceId)
  if (loading) {
    return null
  }
  const { schema, uiSchema } = getSchemas(addOn.config, 'create')

  return (
    <div className={classes.root}>
      <SectionTitle title={`Create New Service - ${addOn.name}`} />
      <Paper className={classes.content}>
        <FormWithRedirect
          save={() => submitButton.current.click()}
          saving={saving}
          redirect={redirectPath}
          render={formProps => (
            <div>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={12}>
                  <Form
                    formData={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    fields={fields}
                    widgets={widgets}
                    showErrorList={false}
                    noHtml5Validate={true}
                    transformErrors={transformErrors}
                    onChange={handleChange}
                    onSubmit={onSave}
                  >
                    <button ref={submitButton} style={{ display: 'none' }} />
                  </Form>
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                <SaveButton
                  size="small"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <Button
                  className={classes.button}
                  component={Link}
                  to={redirectPath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </div>
          )}
        />
      </Paper>
    </div>
  )
}

export default DeploymentServiceCreate
