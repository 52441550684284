import React from 'react'
import { Sidebar as BaseSidebar } from 'react-admin'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const Sidebar = props => {
  const classes = useStyles()
  return <BaseSidebar {...props} className={classes.root} />
}

export default Sidebar
