import React from 'react'
import { Link } from 'react-router-dom'
import { Edit, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    '& .MuiCardContent-root': {
      [theme.breakpoints.up('md')]: {
        width: '50%'
      }
    }
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const Spacer = () => <span style={{ flex: 1 }} />

const EditorToolbar = props => {
  const classes = useStyles()
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <Spacer />
      <SaveButton size="small" />
      <Button
        className={classes.button}
        component={Link}
        to={props.basePath}
        label="Cancel"
        variant="contained"
        color="default"
      />
    </Toolbar>
  )
}

const DeploymentEdit = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <SectionTitle title="Edit Deployment" />
      <Edit {...props}>
        <SimpleForm className={classes.form} toolbar={<EditorToolbar />}>
          <TextInput label="Name" source="name" variant="standard" fullWidth />
          <TextInput
            label="Description"
            source="description"
            variant="standard"
            rows={3}
            multiline
            fullWidth
          />
        </SimpleForm>
      </Edit>
    </div>
  )
}

export default DeploymentEdit
