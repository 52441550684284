import React from 'react'
import TextField from '@material-ui/core/TextField'
import padStart from 'lodash/padStart'

const serialize = (value, format) => {
  if (format === 'cron') {
    let bits = value.split(':')
    return `${bits[1]} ${bits[0]}`
  }
  return value
}

const deserialize = (value, format) => {
  if (format === 'cron') {
    let bits = value.split(' ')
    return `${padStart(bits[1], 2, '0')}:${padStart(bits[0], 2, '0')}`
  }
  return value
}

const TimePickerField = props => {
  const {
    schema,
    name,
    uiSchema,
    idSchema,
    formData,
    required,
    disabled,
    autofocus,
    onChange
  } = props
  const { title } = schema
  const { format } = uiSchema['ui:options'] || { format: 'standard' }
  let value = deserialize(formData, format)

  const _onChange = e => onChange(serialize(e.target.value, format))

  return (
    <TextField
      id={idSchema && idSchema.$id}
      label={title === undefined ? name : title}
      autoFocus={autofocus}
      required={required}
      disabled={disabled}
      onChange={_onChange}
      type="time"
      value={value}
      InputLabelProps={{
        shrink: true
      }}
    />
  )
}

export default TimePickerField
