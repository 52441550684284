import React from 'react'

import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'

const AddButton = props => (
  <Button {...props} color="primary">
    <AddIcon /> Add Item
  </Button>
)

export default AddButton
