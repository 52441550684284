import React from 'react'
import { MenuItem, ListItemIcon, makeStyles } from '@material-ui/core'
import { Logout } from 'mdi-material-ui'
import {logout} from "../../utils/dataProvider";

const useStyles = makeStyles(theme => ({
  menuItem: {
    color: theme.palette.text.secondary
  },
  icon: { minWidth: theme.spacing(5) }
}))

const LogoutButton = () => {
  const classes = useStyles()
  return (
    <MenuItem
      className={classes.menuItem}
      onClick={() => (logout())}
      dense
    >
      <ListItemIcon className={classes.icon}>
        <Logout />
      </ListItemIcon>
      Logout
    </MenuItem>
  )
}

export default LogoutButton
