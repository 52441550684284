import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import yaml from 'js-yaml'
import {
  FormWithRedirect,
  TextInput,
  SaveButton,
  useMutation,
  useNotify,
  useQuery,
  useRedirect,
  required
} from 'react-admin'
import { makeStyles, Grid, Paper, Toolbar } from '@material-ui/core'
import { AceEditorInput, Button, SectionTitle } from '../../components'
import { useAuth } from '../../utils/auth'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const _getRedirectPath = pathname => {
  let idx = pathname.indexOf('/pipelines')
  return `${pathname.substring(0, idx)}/show?tab=2`
}

const _canEdit = (pathname, auth) => {
  if (pathname.endsWith('/show')) {
    return false
  }
  return auth.canEditDeployments()
}

const DeploymentPipelineEdit = ({ location, match }) => {
  const classes = useStyles()
  const [invalidConfig, setInvalidConfig] = useState(false)
  const deploymentId = match.params.id
  const pipelineId = match.params.pipeline_id
  const redirectPath = _getRedirectPath(location.pathname)
  const notify = useNotify()
  const redirect = useRedirect()
  const auth = useAuth()
  const canEdit = _canEdit(location.pathname, auth)

  const { data: pipeline } = useQuery(
    {
      type: 'getOne',
      resource: `deployments/${deploymentId}/pipelines`,
      payload: { id: pipelineId }
    },
    {
      onFailure: () => {
        notify('ra.notification.item_doesnt_exist', 'warning')
        redirect(redirectPath)
      }
    }
  )

  const [save, { loading }] = useMutation()
  const onSave = ({ id, name, description, config }) => {
    const data = {
      name,
      description,
      config: yaml.safeLoad(config),
      schemaVersion: pipeline.schemaVersion,
      sermosPipelineId: pipeline.sermosPipelineId
    }

    save(
      {
        type: 'update',
        resource: `deployments/${deploymentId}/pipelines`,
        payload: { id, data }
      },
      {
        onSuccess: () => {
          notify('Elemented updated', 'info')
          redirect(redirectPath)
        },
        onFailure: error => {
          if (error.status !== 422) {
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning'
            )
          } else {
            if (error.body.config !== undefined) {
              setInvalidConfig(true)
            }
          }
        }
      }
    )
  }

  if (!pipeline) {
    return null
  }

  let record = {
    id: pipeline.id,
    name: pipeline.name,
    description: pipeline.description,
    config: yaml.dump(pipeline.config),
    sermosPipelineId: pipeline.sermosPipelineId
  }

  return (
    <div className={classes.root}>
      <SectionTitle title={canEdit ? 'Edit Pipeline' : 'Pipeline'} />
      <Paper className={classes.content}>
        <FormWithRedirect
          record={record}
          save={onSave}
          saving={loading}
          redirect={redirectPath}
          render={formProps => (
            <form>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    source="id"
                    label="Pipeline ID"
                    variant="standard"
                    disabled
                    fullWidth
                  />
                  <TextInput
                    source="sermosPipelineId"
                    label="Sermos Pipeline ID"
                    variant="standard"
                    disabled
                    fullWidth
                  />
                  <TextInput
                    source="name"
                    label="Name"
                    variant="standard"
                    validate={[required()]}
                    fullWidth
                    disabled={!canEdit}
                  />
                  <TextInput
                    source="description"
                    label="Description"
                    variant="standard"
                    disabled={!canEdit}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <AceEditorInput
                    source="config"
                    label="Config"
                    invalidValue={invalidConfig}
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                {canEdit && (
                  <SaveButton
                    size="small"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                )}
                <Button
                  className={classes.button}
                  component={Link}
                  to={redirectPath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default DeploymentPipelineEdit
