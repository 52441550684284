import React from 'react'
import PropTypes from 'prop-types'
import { linkToRecord } from 'react-admin'

import Button from '../Button'
import ContentCreate from '@material-ui/icons/Create'

const EditButton = ({ basePath, onClick, record, to, ...rest }) => {
  const linkTo = to ? to : linkToRecord(basePath, record && record.id)
  const _onClick = () => onClick(record)

  return (
    <Button
      {...rest}
      onClick={_onClick}
      to={linkTo}
      tooltip="Edit"
      Icon={ContentCreate}
    />
  )
}

EditButton.propTypes = {
  onClick: PropTypes.func
}

EditButton.defaultProps = {
  onClick: () => {}
}

export default EditButton
