import React from 'react'
import PropTypes from 'prop-types'

import { Button, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    right: 20,
    top: 5
  }
})

const LoadingButton = props => {
  const { label, loading, onClick } = props
  const classes = useStyles()
  return (
    <div style={{ position: 'relative' }}>
      <Button
        variant="contained"
        color="primary"
        disableElevation
        onClick={onClick}
        disabled={loading}
      >
        {label}
      </Button>

      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  )
}

LoadingButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func
}

LoadingButton.defaultProps = {
  loading: false,
  onClick: () => {}
}

export default LoadingButton
