import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import Button from '../Button'
import FileCopy from '@material-ui/icons/FileCopy'

const CopyButton = ({ basePath, recordId }) => (
  <Button
    component={Link}
    to={{
      pathname: `${basePath}/create`,
      search: `?sourceId=${recordId}`
    }}
    tooltip="Copy"
    Icon={FileCopy}
  />
)

CopyButton.propTypes = {
  onClick: PropTypes.func
}

CopyButton.defaultProps = {
  basePath: PropTypes.string.isRequired,
  recordId: PropTypes.node.isRequired
}

export default CopyButton
