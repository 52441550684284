import React from 'react'
import { Link } from 'react-router-dom'
import { useStore } from 'react-redux'
import { Create, SimpleForm, TextInput, Toolbar, SaveButton } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    '& .MuiCardContent-root': {
      [theme.breakpoints.up('md')]: {
        width: '50%'
      }
    }
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const Spacer = () => <span style={{ flex: 1 }} />

const EditorToolbar = props => {
  const classes = useStyles()
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <Spacer />
      <SaveButton size="small" />
      <Button
        className={classes.button}
        component={Link}
        to={props.basePath}
        label="Cancel"
        variant="contained"
        color="default"
      />
    </Toolbar>
  )
}

const DeploymentCreate = props => {
  const classes = useStyles()
  const { customer, user } = useStore().getState()
  let defaults = {}
  if (customer) {
    defaults['organizationId'] = customer
  }
  return (
    <div className={classes.root}>
      <SectionTitle title="Create New Deployment" />
      <Create {...props}>
        <SimpleForm
          className={classes.form}
          initialValues={defaults}
          toolbar={<EditorToolbar />}
        >
          <TextInput label="Name" source="name" variant="standard" fullWidth />
          <TextInput
            label="Description"
            source="description"
            variant="standard"
            rows={3}
            multiline
            fullWidth
          />
          {user.isSysAdmin && (
            <TextInput
              label="Organization ID"
              source="organizationId"
              variant="standard"
              fullWidth
            />
          )}
        </SimpleForm>
      </Create>
    </div>
  )
}

export default DeploymentCreate
