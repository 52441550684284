import React from 'react'

import { makeStyles } from '@material-ui/core'
import { Edit } from 'react-admin'
import { SectionTitle } from '../../components'
import { OrgWrapper } from '../Organization'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  }
}))

const OrganizationEdit = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SectionTitle title="Edit Organization" />
      <Edit {...props}>
        <OrgWrapper />
      </Edit>
    </div>
  )
}

export default OrganizationEdit
