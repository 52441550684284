import React from 'react'
import { Link } from 'react-router-dom'
import { FunctionField, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  ListActions,
  ListView
} from '../../components'
import { useAuth } from '../../utils/auth'

const useStyles = makeStyles(theme => ({
  list: {
    marginTop: -theme.spacing(3)
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const getActions = deploymentId => {
  const Actions = props => (
    <ListActions {...props}>
      <Button
        component={Link}
        to={`${props.basePath}/${deploymentId}/pipelines/create`}
        label="+ Create"
        disableElevation
      />
    </ListActions>
  )
  return Actions
}

const DeploymentPipelinesEditTab = ({ id }) => {
  const classes = useStyles()
  const Actions = getActions(id)
  const auth = useAuth()
  const canEdit = auth.canEditDeployments()

  return (
    <ListView
      className={classes.list}
      bulkActionButtons={false}
      basePath="/deployments"
      component="div"
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow={false}
      filter={{ deploymentId: id }}
      resource="deployment_pipelines"
      sort={{ field: 'name', order: 'ASC' }}
      actions={canEdit ? <Actions /> : null}
    >
      <FunctionField
        label="Name"
        render={record => (
          <Link
            className={classes.link}
            to={`/deployments/${id}/pipelines/${record.id}/show`}
          >
            {record.name}
          </Link>
        )}
      />
      <TextField label="Description" source="description" />
      <DateField label="Created At" source="addedDatetime" />
      <DateField label="Updated At" source="changedDatetime" />
      {canEdit && (
        <FunctionField
          render={record => (
            <EditButton
              component={Link}
              to={`/deployments/${id}/pipelines/${record.id}`}
            />
          )}
        />
      )}
      {canEdit && (
          <FunctionField
            render={record => (
              <DeleteButton
                basePath={`deployments/${id}`}
                record={{ id: record.id, deploymentId: id }}
                resource="deployment_pipelines"
                redirect={false}
                confirmContent="Are you sure you want to remove this pipeline?"
              />
            )}
          />
      )}
    </ListView>
  )
}

export default DeploymentPipelinesEditTab
