import React from 'react'
import PropTypes from 'prop-types'
import { Typography, makeStyles } from '@material-ui/core'
import NavigateBefore from '@material-ui/icons/NavigateBefore'
import Button from '../Button'

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: -5
  },
  titleWithButton: {
    paddingLeft: theme.spacing(3),
    color: theme.palette.text.primary
  },
  title: {
    color: theme.palette.text.primary
  }
}))

const SectionTitle = ({ title, history = null }) => {
  const classes = useStyles()
  if (history) {
    return (
      <>
        <Button
          className={classes.button}
          label="Back"
          Icon={NavigateBefore}
          variant="text"
          onClick={() => history.goBack()}
        />
        <Typography
          className={classes.titleWithButton}
          variant="h5"
          compontent="span"
          display="inline"
        >
          {title}
        </Typography>
      </>
    )
  }
  return (
    <Typography
      className={classes.title}
      variant="h5"
      compontent="span"
      display="inline"
    >
      {title}
    </Typography>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string
}

export default SectionTitle
