import React from 'react'
import PropTypes from 'prop-types'
import AceEditor from 'react-ace'
import yaml from 'js-yaml'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Labeled, useInput } from 'react-admin'

const validateYaml = value => {
  try {
    yaml.safeLoad(value)
  } catch (err) {
    return `^${err.reason} - line: ${err.mark.line}.`
  }
  return []
}

const AceEditorInput = ({ invalidValue, validate = [], ...rest }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  validate.push(validateYaml)
  const {
    input: { onChange, value },
    meta: { touched, error },
    isRequired
  } = useInput({ ...rest, validate })

  return (
    <>
      <Labeled
        label={rest.label}
        meta={{ touched, error }}
        isRequired={isRequired}
      >
        <AceEditor
          mode="yaml"
          theme={theme.palette.type === 'light' ? 'github' : 'twilight'}
          name="editor"
          onChange={onChange}
          value={value}
          width={fullScreen ? '95vw' : '800px'}
          tabSize={2}
          editorProps={{ $blockScrolling: true }}
        />
      </Labeled>
      <br />
      {((touched && error) || (!touched && invalidValue)) && (
        <Typography variant="caption" color="error">
          Invalid value
        </Typography>
      )}
    </>
  )
}

AceEditorInput.propTypes = {
  className: PropTypes.string,
  invalidValue: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string
}

export default AceEditorInput
