import React from 'react'
import { Link } from 'react-router-dom'

import { makeStyles } from '@material-ui/core'
import {
  BooleanInput,
  Create,
  SimpleForm,
  TextInput,
  Toolbar,
  SaveButton
} from 'react-admin'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const Spacer = () => <span style={{ flex: 1 }} />

const EditorToolbar = props => {
  const classes = useStyles()
  return (
    <Toolbar {...props} className={classes.toolbar}>
      <Spacer />
      <SaveButton size="small" />
      <Button
        className={classes.button}
        component={Link}
        to={props.basePath}
        label="Cancel"
        variant="contained"
        color="default"
      />
    </Toolbar>
  )
}

const OrganizationCreate = props => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <SectionTitle title="Create Customer" />
      <Create {...props}>
        <SimpleForm toolbar={<EditorToolbar />}>
          <TextInput source="name" label="Name" variant="standard" />
          <BooleanInput source="enableSupport" label="Enable Support" />
        </SimpleForm>
      </Create>
    </div>
  )
}

export default OrganizationCreate
