import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, Toolbar } from '@material-ui/core'
import {
  FormWithRedirect,
  SelectInput,
  TextInput,
  SaveButton,
  useMutation,
  useNotify,
  useRefresh,
  useRedirect,
  required
} from 'react-admin'
import { Button } from '../../components'

const useStyles = makeStyles(theme => ({
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const OrgUserEditGeneralTab = ({ basePath, customerId, userId, record }) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()

  const [save, { loading: saving }] = useMutation()
  const onSave = data => {
    save(
      {
        type: 'update',
        resource: `organizations/${customerId}/users`,
        payload: { id: userId, data }
      },
      {
        onSuccess: () => {
          notify('User has been updated')
          redirect(basePath)
          refresh()
        },
        onFailure: error => {
          notify(error.message, 'warning')
          redirect(basePath)
          refresh()
        }
      }
    )
  }

  return (
    <FormWithRedirect
      save={onSave}
      saving={saving}
      record={record}
      redirect={basePath}
      render={formProps => {
        const record = formProps.record
        return (
          <form>
            <Grid container className={classes.form}>
              <Grid item xs={12} lg={6}>
                <TextInput
                  source="id"
                  label="User ID"
                  variant="standard"
                  disabled
                  fullWidth
                />
                {record.type === 'console' ? (
                  <TextInput
                    source="email"
                    label="Email"
                    type="email"
                    variant="standard"
                    validate={[required()]}
                    fullWidth
                  />
                ) : (
                  <TextInput
                    source="name"
                    label="Name"
                    variant="standard"
                    validate={[required()]}
                    fullWidth
                  />
                )}
                <SelectInput
                  source="role"
                  label="Role"
                  variant="standard"
                  choices={[
                    { id: 'Admin', name: 'Administrator' },
                    { id: 'Editor', name: 'Editor' },
                    { id: 'Viewer', name: 'Viewer' }
                  ]}
                  validate={[required()]}
                />
              </Grid>
            </Grid>
            <Toolbar className={classes.toolbar}>
              <span style={{ flex: 1 }} />
              <SaveButton
                size="small"
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
              <Button
                className={classes.button}
                component={Link}
                to={basePath}
                label="Cancel"
                variant="contained"
                color="default"
              />
            </Toolbar>
          </form>
        )
      }}
    />
  )
}

export default OrgUserEditGeneralTab
