import React from 'react'
import ObjectField from '@rjsf/core/dist/cjs/components/fields/ObjectField'

const Services = ({ properties }) => properties.map(prop => prop.content)

const ServicesField = ({ uiSchema, ...rest }) => {
  uiSchema['ui:ObjectFieldTemplate'] = Services
  return <ObjectField {...rest} uiSchema={uiSchema} />
}

export default ServicesField
