import React from 'react'
import { Link } from 'react-router-dom'
import { FunctionField, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  DeleteButton,
  EditButton,
  ListActions,
  ListView
} from '../../components'

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: 0
  }
}))

const Actions = props => {
  return (
    <ListActions {...props}>
      <Button
        component={Link}
        to={`${props.basePath}/users/add`}
        label="+ Add User"
        disableElevation
      />
    </ListActions>
  )
}

const UsersTab = ({ basePath, org }) => {
  const classes = useStyles()
  return (
    <ListView
      className={classes.list}
      bulkActionButtons={false}
      basePath={basePath}
      component="div"
      hasCreate={false}
      hasEdit={false}
      hasList
      hasShow={false}
      actions={<Actions />}
      filter={{ orgId: org.id }}
      resource="org_users"
      sort={{ field: 'name', order: 'ASC' }}
    >
      <FunctionField
        label="Name"
        render={record => (record.name ? record.name : record.email)}
      />
      <TextField label="Username" source="email" />
      <TextField label="Type" source="type" />
      <FunctionField
        render={record => (
          <EditButton component={Link} to={`${basePath}/users/${record.id}`} />
        )}
      />
      <FunctionField
        render={record => (
          <DeleteButton
            basePath={`organization/users/${record.id}`}
            record={{ id: record.id, orgId: org.id }}
            resource="org_users"
            redirect={false}
            confirmContent="Are you sure you want to delete this user?"
          />
        )}
      />
    </ListView>
  )
}

export default UsersTab
