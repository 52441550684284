import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  makeStyles
} from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { ContentCopy } from 'mdi-material-ui'

import { LoadingButton } from '../../components'

const useStyles = makeStyles(theme => ({
  form: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(2)
    }
  }
}))

const OrgUserAccessKeyDialog = props => {
  const { error, loading, open, description, apiKey, onSave, onCancel } = props
  const classes = useStyles()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const [invalid, setInvalid] = useState(false)
  const [values, setValues] = useState({
    apiKey: apiKey || '',
    description: description || ''
  })
  const onChange = key => e => {
    const newValues = Object.assign({}, values, {
      [key]: e.target.value
    })
    setValues(newValues)
  }
  const onCancelClick = () => {
    setValues({ apiKey: '', description: '' })
    onCancel()
  }
  const onSaveClick = () => {
    if (values.description === '') {
      setInvalid(true)
      return
    }
    onSave(values)
  }

  useEffect(() => {
    setValues({
      apiKey: apiKey || '',
      description: description || ''
    })
  }, [apiKey, description])

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullScreen={fullScreen}
    >
      <DialogTitle id="form-dialog-title">New Access Key</DialogTitle>
      <DialogContent
        style={{
          width: fullScreen ? `cal(100%-${theme.spacing(3)})` : 450,
          minHeight: 230
        }}
      >
        {apiKey !== '' && (
          <>
            <Alert
              severity="success"
              action={
                <Tooltip title="Copy" aria-label="copy" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => navigator.clipboard.writeText(apiKey)}
                  >
                    <ContentCopy />
                  </IconButton>
                </Tooltip>
              }
            >
              {apiKey}
            </Alert>
            <br />
            <Typography variant="body2">
              Please copy and save the access key above. You will not be able to
              retrieve it once you close this dialog.
            </Typography>
          </>
        )}
        {apiKey === '' && error && (
          <>
            <Alert severity="error">{error}</Alert>
            <br />
            <br />
          </>
        )}
        {apiKey === '' && (
          <form className={classes.form}>
            <TextField
              id="api-key"
              type="text"
              label="Access Key"
              value={values.apiKey}
              onChange={onChange('apiKey')}
              placeholder="Enter your key or leave blank to generate"
              fullWidth
              autoFocus
            />
            <TextField
              id="description"
              type="text"
              label="Description"
              value={values.description}
              onChange={onChange('description')}
              error={invalid}
              helperText={invalid ? 'Required' : ''}
              fullWidth
              required
            />
          </form>
        )}
      </DialogContent>
      <DialogActions>
        {apiKey === '' && (
          <LoadingButton label="Save" onClick={onSaveClick} loading={loading} />
        )}
        <Button onClick={onCancelClick} variant="contained" disableElevation>
          {apiKey === '' ? 'Cancel' : 'Close'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrgUserAccessKeyDialog.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  apiKey: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

OrgUserAccessKeyDialog.defaultProps = {
  loading: false,
  open: false
}

export default OrgUserAccessKeyDialog
