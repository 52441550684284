import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Paper, Tab, Tabs, makeStyles } from '@material-ui/core'
import { SectionTitle, TabPanel } from '../../components'
import UserAccountEdit from './UserAccountEdit'
import UserAccountCredentialsEdit from './UserAccountCredentialsEdit'
import OrgUserEditAccessKeysTab from '../Organization/OrgUserEditAccessKeysTab'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  tab: {
    '& .MuiBox-root': {
      padding: 0
    }
  }
}))

const UserAccountShow = props => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const user = useSelector(state => state.user)
  return (
    <div className={classes.root}>
      <SectionTitle title="My Account" />
      <Paper className={classes.content}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="General" value={0} />
          <Tab label="Credentials" value={1} />
          <Tab label="Access Keys" value={2} />
        </Tabs>
        <TabPanel index={0} value={selectedTab} className={classes.tab}>
          <UserAccountEdit />
        </TabPanel>
        <TabPanel index={1} value={selectedTab} className={classes.tab}>
          <UserAccountCredentialsEdit />
        </TabPanel>
        <TabPanel index={2} value={selectedTab}>
          <OrgUserEditAccessKeysTab userId={user.id} />
        </TabPanel>
      </Paper>
    </div>
  )
}

export default UserAccountShow
