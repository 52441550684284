import React, { useState } from 'react'
import { useStore } from 'react-redux'
import { makeStyles, Paper, Tab, Tabs } from '@material-ui/core'
import { useNotify, useRefresh, useRedirect, useQuery } from 'react-admin'
import { SectionTitle, TabPanel } from '../../components'
import OrgUserEditGeneralTab from './OrgUserEditGeneralTab'
import OrgUserEditAccessKeysTab from './OrgUserEditAccessKeysTab'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const _getRedirectPath = pathname => {
  let idx = pathname.indexOf('/users')
  return pathname.substring(0, idx)
}

const OrgUserEdit = ({ location, match }) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const { customer } = useStore().getState()
  const customerId = match.params.org_id || customer
  const notify = useNotify()
  const refresh = useRefresh()
  const redirect = useRedirect()
  const redirectPath = _getRedirectPath(location.pathname)

  const { data: user } = useQuery(
    {
      type: 'getOne',
      resource: `organizations/${customerId}/users`,
      payload: { id: match.params.id }
    },
    {
      onFailure: () => {
        notify('ra.notification.item_doesnt_exist', 'warning')
        redirect(redirectPath)
        refresh()
      }
    }
  )

  if (!user) {
    return null
  }

  return (
    <div className={classes.root}>
      <SectionTitle title="Edit User" />
      <Paper className={classes.content}>
        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          textColor="primary"
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
        >
          <Tab label={'General'} value={0} />
          {user.type === 'programmatic' && (
            <Tab label={'Access Keys'} value={1} />
          )}
        </Tabs>
        <TabPanel index={0} value={selectedTab}>
          <OrgUserEditGeneralTab
            basePath={redirectPath}
            customerId={customerId}
            userId={match.params.id}
            record={user}
          />
        </TabPanel>
        {user.type === 'programmatic' && (
          <TabPanel index={1} value={selectedTab}>
            <OrgUserEditAccessKeysTab
              basePath={redirectPath}
              customerId={customer}
              userId={match.params.id}
            />
          </TabPanel>
        )}
      </Paper>
    </div>
  )
}

export default OrgUserEdit
