import React, { useState, useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Route } from 'react-router-dom'
import { Admin, Resource } from 'react-admin'
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  makeStyles
} from '@material-ui/core'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import englishMessages from 'ra-language-english'
import axios from 'axios'

import { customerReducer, themeReducer, userReducer } from './redux'
import CustomLayout from './components/Layout'
import dataProvider from './utils/dataProvider'
import withConfig from './utils/withConfig'
import Home from './views/Home'
import {
  OrganizationsList,
  OrganizationCreate,
  OrganizationEdit
} from './views/Organizations'
import { UsersList, UserCreate, UserEdit } from './views/Users'
import {
  DeploymentsList,
  DeploymentShow,
  DeploymentCreate,
  DeploymentEdit,
  DeploymentPipelineCreate,
  DeploymentPipelineEdit,
  DeploymentTaskCreate,
  DeploymentTaskEdit,
  DeploymentServiceCreate,
  DeploymentServiceEdit
} from './views/Deployments'
import { OrgWrapper, OrgUserCreate, OrgUserEdit } from './views/Organization'
import { UserAccountShow } from './views/Account'
import {
  AccountMultipleOutline,
  HexagonMultiple,
  PentagonOutline
} from 'mdi-material-ui'
import { isAdmin, isEditor, isViewer } from './utils/auth'

const history = createBrowserHistory()

englishMessages.ra.page.dashboard = 'Home'
const i18nProvider = polyglotI18nProvider(locale => englishMessages, 'en', {
  allowMissing: true
})

const useStyles = makeStyles(theme => ({
  error: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
}))

const getCustomRoutes = (
  isCustomerAdmin,
  isCustomerEditor,
  isCustomerViewer
) => {
  let customRoutes = [
    <Route exact path="/myaccount" component={UserAccountShow} />
  ]
  if (isCustomerAdmin) {
    customRoutes = customRoutes.concat([
      <Route exact path="/organization/users/add" component={OrgUserCreate} />,
      <Route
        exact
        path="/organizations/:id/users/add"
        component={OrgUserCreate}
      />,
      <Route exact path="/organization/users/:id" component={OrgUserEdit} />,
      <Route
        exact
        path="/organizations/:org_id/users/:id"
        component={OrgUserEdit}
      />
    ])
  }
  if (isCustomerEditor) {
    customRoutes = customRoutes.concat([
      <Route
        exact
        path="/deployments/:id/pipelines/create"
        component={DeploymentPipelineCreate}
      />,
      <Route
        exact
        path="/deployments/:id/pipelines/:pipeline_id"
        component={DeploymentPipelineEdit}
      />,
      <Route
        exact
        path="/deployments/:id/scheduled_tasks/create"
        component={DeploymentTaskCreate}
      />,
      <Route
        exact
        path="/deployments/:id/scheduled_tasks/:task_id"
        component={DeploymentTaskEdit}
      />,
      <Route
        exact
        path="/deployments/:id/services/create"
        component={DeploymentServiceCreate}
      />,
      <Route
        exact
        path="/deployments/:id/services/:service_id"
        component={DeploymentServiceEdit}
      />
    ])
  }

  if (isCustomerEditor || isCustomerViewer) {
    customRoutes = customRoutes.concat([
      <Route
        exact
        path="/deployments/:id/pipelines/:pipeline_id/show"
        component={DeploymentPipelineEdit}
      />,
      <Route
        exact
        path="/deployments/:id/scheduled_tasks/:task_id/show"
        component={DeploymentTaskEdit}
      />,
      <Route
        exact
        path="/deployments/:id/services/:service_id/show"
        component={DeploymentServiceEdit}
      />
    ])
  }

  return customRoutes
}

const getResources = isSysAdmin => {
  const resources = [
    <Resource key="access_keys" name="access_keys" list={null} />,
    <Resource key="user_access_keys" name="user_access_keys" list={null} />,
    <Resource key="deployment_status" name="deployment_status" list={null} />,
    <Resource key="org_users" name="org_users" list={null} />,
    <Resource key="deployment_users" name="deployment_users" list={null} />,
    <Resource
      key="deployment_pipelines"
      name="deployment_pipelines"
      list={null}
    />,
    <Resource
      key="deployment_scheduled_tasks"
      name="deployment_scheduled_tasks"
      list={null}
    />,
    <Resource key="services" name="services" list={null} />,
    <Resource
      key="deployment_services"
      name="deployment_services"
      list={null}
    />
  ]
  if (isSysAdmin) {
    resources.push(
      <Resource
        key="organizations"
        name="organizations"
        options={{ label: 'Organizations' }}
        list={OrganizationsList}
        create={OrganizationCreate}
        edit={OrganizationEdit}
        icon={HexagonMultiple}
      />
    )
    resources.push(
      <Resource
        key="users"
        name="users"
        options={{ label: 'Users' }}
        list={UsersList}
        create={UserCreate}
        edit={UserEdit}
        icon={AccountMultipleOutline}
      />
    )
    resources.push(
      <Resource
        key="deployments"
        name="deployments"
        options={{ label: 'Deployments' }}
        list={DeploymentsList}
        create={DeploymentCreate}
        edit={DeploymentEdit}
        show={DeploymentShow}
      />
    )
  } else {
    resources.push(<Resource key="organizations" name="organizations" />)
    resources.push(
      <Resource
        key="organization"
        name="organization"
        options={{ label: 'Organization' }}
        list={OrgWrapper}
        icon={PentagonOutline}
      />
    )
    resources.push(
      <Resource
        key="deployments"
        name="deployments"
        options={{ label: 'Deployments' }}
        list={DeploymentsList}
        create={DeploymentCreate}
        edit={DeploymentEdit}
        show={DeploymentShow}
      />
    )
  }
  return resources
}

const App = props => {
  const classes = useStyles()
  const [user, setUser] = useState(null)
  const [config, setConfig] = useState(null)
  const [error, setError] = useState(null)

  useEffect(() => {
    async function fetch() {
      Promise.all([axios.get('/api/v1/users/current'), axios.get('/config')])
        .then(values => {
          setUser(values[0].data.data)
          setConfig(values[1].data.data)
        })
        .catch(err => {
          setError('Error pre-fetching required information')
        })
    }
    fetch()
  }, [])

  if ((!user || !config) && !error) {
    return (
      <Backdrop
        className={classes.backdrop}
        open={(!user || !config) && !error}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    )
  }

  if (error) {
    return (
      <Snackbar
        ContentProps={{ className: classes.error }}
        open={error !== null}
        autoHideDuration={5000}
        message={error}
        onClose={() => setError(null)}
      />
    )
  }

  const selectedCustomer = user.isSysAdmin
    ? null
    : user.defaultCustomer || user.customers[0].id

  const isCustomerAdmin = isAdmin(user, selectedCustomer)
  const isCustomerEditor = isEditor(user, selectedCustomer)
  const isCustomerViewer = isViewer(user, selectedCustomer)

  const initialState = {
    user,
    customer: selectedCustomer
  }

  return (
    <Admin
      locale="en"
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      layout={CustomLayout}
      initialState={initialState}
      history={history}
      dashboard={withConfig(Home, config)}
      customRoutes={getCustomRoutes(
        isCustomerAdmin,
        isCustomerEditor,
        isCustomerViewer
      )}
      customReducers={{
        customer: customerReducer,
        theme: themeReducer,
        user: userReducer
      }}
    >
      {getResources(user.isSysAdmin)}
    </Admin>
  )
}

export default App
