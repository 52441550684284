import React, { useState } from 'react'
import ObjectField from '@rjsf/core/dist/cjs/components/fields/ObjectField'
import { Badge, Tab, Tabs, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  content: {
    paddingTop: theme.spacing(3)
  }
}))

const renderTabLabel = (text, error) => {
  if (error) {
    return (
      <Badge color="error" variant="dot">
        {text}
      </Badge>
    )
  }
  return <span>{text}</span>
}

const Sections = ({ formContext: { tabErrors }, properties, schema }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()

  return (
    <>
      <Tabs
        className={classes.tabs}
        value={selectedTab}
        indicatorColor="primary"
        textColor="primary"
        onChange={(event, value) => setSelectedTab(value)}
      >
        {properties.map((prop, idx) => {
          const title = schema.properties[prop.name].title
          return (
            <Tab
              key={prop.name}
              label={renderTabLabel(title, tabErrors.indexOf(prop.name) !== -1)}
              value={idx}
            />
          )
        })}
      </Tabs>
      <div className={classes.content}>{properties[selectedTab].content}</div>
    </>
  )
}

const SectionsField = ({ uiSchema, ...rest }) => {
  uiSchema['ui:ObjectFieldTemplate'] = Sections
  return <ObjectField {...rest} uiSchema={uiSchema} />
}

export default SectionsField
