import React from 'react'
import { Link } from 'react-router-dom'
import { BooleanField, FunctionField, TextField } from 'react-admin'
import { DeleteButton, EditButton, ListView } from '../../components'

const UsersList = props => {
  const { match } = props
  return (
    <div>
      <ListView
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
      >
        <TextField label="Name" source="name" />
        <TextField label="Username" source="email" />
        <TextField label="Type" source="type" />
        <BooleanField label="System Admin" source="isSysAdmin" />
        <BooleanField label="Enabled" source="enabled" />
        <FunctionField
          render={record => (
            <EditButton component={Link} to={`${match.url}/${record.id}`} />
          )}
        />
        <FunctionField
          render={record =>
            record.type === 'programmatic' ? (
              <DeleteButton
                basePath="/users"
                record={record}
                resource="users"
                redirect={false}
                confirmContent="Are you sure you want to delete this user?"
              />
            ) : null
          }
        />
      </ListView>
    </div>
  )
}

export default UsersList
