import React, { useState } from 'react'
import { useStore } from 'react-redux'
import { useShowController } from 'react-admin'
import { Paper, Tab, Tabs, makeStyles } from '@material-ui/core'
import { SectionTitle, TabPanel } from '../../components'
import DeploymentEditGeneralTab from './DeploymentEditGeneralTab'
import DeploymentServicesTab from './DeploymentServicesTab'
import DeploymentEditUsersTab from './DeploymentEditUsersTab'
import DeploymentPipelinesEditTab from './DeploymentPipelinesEditTab'
import DeploymentTasksEditTab from './DeploymentTasksEditTab'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  tabs: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const DeploymentEdit = ({ match, location, ...rest }) => {
  const classes = useStyles()

  const { customer, user } = useStore().getState()
  const currentCustomer = user.customers.filter(c => c.id === customer)[0]

  const searchParams = new URLSearchParams(location.search)
  let tab = searchParams.get('tab')
  tab = tab === null || isNaN(tab) ? 0 : parseInt(tab)
  const [selectedTab, setSelectedTab] = useState(tab)

  const { record, version } = useShowController({
    basePath: '/deployments',
    resource: 'deployments',
    id: match.params.id
  })
  if (!record) {
    return null
  }

  return (
    <div className={classes.root}>
      <SectionTitle title="Deployment Details" />
      <Paper className={classes.content}>
        <Tabs
          className={classes.tabs}
          value={selectedTab}
          onChange={(event, value) => setSelectedTab(value)}
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="General" value={0} />
          <Tab label="Services" value={1} />
          <Tab label="Pipelines" value={2} />
          <Tab label="Scheduled Tasks" value={3} />
          {(user.isSysAdmin || currentCustomer.role === 'Admin') && (
            <Tab label="Users" value={4} />
          )}
        </Tabs>
        <TabPanel index={0} value={selectedTab}>
          <DeploymentEditGeneralTab record={record} version={version} />
        </TabPanel>
        <TabPanel index={1} value={selectedTab}>
          <DeploymentServicesTab id={match.params.id} />
          <div style={{ fontSize: '12px', color: '#eee' }}>
            <b>Status Legend:</b>
            <dl>
              <dt>Active</dt>
              <dd>Service is deployed successfully. Note: this does *not*
              indicate that the service is "operational", as there are any
              number of reasons a deployed service is non-functional (e.g.
              an application error).
              </dd>
              <dt>Created</dt>
              <dd>The service was created, likely through the web UI, and is
              available as a service to be referenced in your application
              and/or Sermos.yaml.</dd>
              <dt>Pending</dt>
              <dd>The service was created, likely through the web UI, and is
              available as a service to be referenced in your application
              and/or Sermos.yaml. It will not be deployed until you run
              a `sermos deploy` command with this new service id referenced.
              </dd>
              <dt>Deploying</dt>
              <dd>A roling deployment is actively underway. Check back soon.</dd>
              <dt>Modifying</dt>
              <dd>A deployment is actively underway and it is in the middle of
              the build process. Check back soon.</dd>
              <dt>Stopped</dt>
              <dd>The deployment of the service was stopped.</dd>
              <dt>Error</dt>
              <dd>There was an error during the deployment. Check your
              configuration and consult available logs &amp; CLI output.
              </dd>
            </dl>
          </div>
        </TabPanel>
        <TabPanel index={2} value={selectedTab}>
          <DeploymentPipelinesEditTab id={match.params.id} />
        </TabPanel>
        <TabPanel index={3} value={selectedTab}>
          <DeploymentTasksEditTab id={match.params.id} />
        </TabPanel>
        {(user.isSysAdmin || currentCustomer.role === 'Admin') && (
          <TabPanel index={4} value={selectedTab}>
            <DeploymentEditUsersTab
              id={match.params.id}
              organizationId={record.organizationId}
            />
          </TabPanel>
        )}
      </Paper>
    </div>
  )
}
export default DeploymentEdit
