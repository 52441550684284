import React from 'react'
import classnames from 'classnames'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`
  }
}))

const Heading = ({ children, className, ...rest }) => {
  const classes = useStyles()
  return (
    <Typography className={classnames(classes.root, className)} {...rest}>
      {children}
    </Typography>
  )
}

export default Heading
