import React from 'react'
import PropTypes from 'prop-types'
import {
  Container, Paper, Typography, makeStyles, useTheme
} from '@material-ui/core'
import processFlowDark from '../../images/Process_flow_dark_background_v2.png'
import processFlowLight from '../../images/Process_flow_white_background_v2.png'
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    textAlign: 'center'
  },
  paper: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(3)
  }
}))

const Home = props => {
  const { config } = props
  const classes = useStyles()
  const theme = useTheme()
  return (
    <div className={classes.root}>
      <Container maxWidth="md">
        <Paper className={classes.paper}>
          <Typography variant="h3" gutterBottom>
            Sermos Cloud
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Manage deployments, databases, pipelines, scheduled tasks, API keys
            and more through your Sermos Cloud Console.
          </Typography>
          <br />
          <img
            style={{ maxWidth: '100%' }}
            src={theme.palette.type === 'light' ? processFlowLight : processFlowDark}
            alt="Sermos"
          />
          <br />
          <Typography variant="subtitle1">
            Sermos Cloud v{config.app_version}
          </Typography>
        </Paper>
      </Container>
    </div>
  )
}

Home.propTypes = {
  config: PropTypes.object
}

export default Home
