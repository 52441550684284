import React from 'react'
import { useStore } from 'react-redux'
import { Link } from 'react-router-dom'
import { FunctionField, TextField } from 'react-admin'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  DateField,
  DeleteButton,
  EditButton,
  ListActions,
  ListView
} from '../../components'
import { useAuth } from '../../utils/auth'

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  }
}))

const getCustomerIdFromUrl = location => {
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get('customer_id')
}

const DeploymentsListActions = props => {
  const { canEdit, ...rest } = props
  return (
    <ListActions {...rest}>
      {canEdit && (
        <Button
          component={Link}
          to={`${window.location.pathname}/create`}
          label="+ Create"
        />
      )}
    </ListActions>
  )
}

const CustomerDeployments = props => {
  const { match, location } = props
  const classes = useStyles()
  const auth = useAuth()
  const { customer, user } = useStore().getState()
  const isAdminUser = auth.isSysAdmin()
  const canEdit = auth.canEditDeployments()
  const customerId = isAdminUser ? getCustomerIdFromUrl(location) : customer
  const filter = { customer_id: customerId }
  let customerName = null
  if (!isAdminUser) {
    customerName = user.customers.filter(c => c.id === customerId)[0].name
  }

  return (
    <div>
      <ListView
        bulkActionButtons={false}
        basePath="/deployments"
        showBackBtn={location.search === '' ? false : true}
        filter={filter}
        hasCreate={false}
        hasEdit={false}
        hasList
        hasShow={false}
        options={{
          label: `Deployments${isAdminUser ? '' : ' - ' + customerName}`
        }}
        resource="deployments"
        sort={{ field: 'id', order: 'ASC' }}
        actions={<DeploymentsListActions canEdit={canEdit} />}
      >
        <FunctionField
          label="Name"
          render={record => (
            <Link
              className={classes.link}
              to={`${match.url}/${record.id}/show`}
            >
              {record.name}
            </Link>
          )}
        />
        <TextField label="Namespace" source="k8sNamespace" />
        <TextField label="ID" source="id" />
        <DateField label="Created At" source="addedDatetime" />
        {canEdit && (
          <>
            <FunctionField
              render={record => (
                <EditButton component={Link} to={`${match.url}/${record.id}`} />
              )}
            />

            <FunctionField
              render={record => (
                <DeleteButton
                  basePath={match.url}
                  record={record}
                  resource="deployments"
                  confirmContent="Are you sure you want to delete this deployment"
                />
              )}
            />
          </>
        )}
      </ListView>
    </div>
  )
}

export default CustomerDeployments
