import React from 'react'
import { useStore } from 'react-redux'
import { AppBar as BaseAppBar } from 'react-admin'
import { Button, Divider, makeStyles, useTheme } from '@material-ui/core'

import CustomerMenu from './CustomerMenu'
import UserMenu from './UserMenu'
import logoPurple from '../../images/sermos_purple_RGB.png'
import logoWhite from '../../images/sermos_white_RGB.png'

const useStyles = makeStyles({
  logo: {
    height: 30
  },
  spacer: {
    flex: 1
  }
})

const AppBar = props => {
  const classes = useStyles()
  const theme = useTheme()
  const store = useStore()
  const { user } = store.getState()

  return (
    <BaseAppBar {...props} color="transparent" userMenu={<UserMenu />}>
      <img
        className={classes.logo}
        src={theme.palette.type === 'light' ? logoPurple : logoWhite}
        alt="Sermos"
      />
      <span className={classes.spacer} />
      {!user.isSysAdmin && (
        <>
          <CustomerMenu />
          <Divider orientation="vertical" flexItem />
        </>
      )}
      <Button
        target="_blank"
        href="/api/v1/docs"
        color="primary"
        variant="text"
      >
        Api Docs
      </Button>
    </BaseAppBar>
  )
}

export default AppBar
