import React from 'react'
import AceEditor from 'react-ace'

import 'ace-builds/src-noconflict/mode-yaml'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/theme-twilight'

import { useTheme } from '@material-ui/core'

const EditorWidget = ({ value, onChange, options }) => {
  const theme = useTheme()
  const _onChange = value => onChange(value === '' ? options.emptyValue : value)
  return (
    <AceEditor
      mode="yaml"
      theme={theme.palette.type === 'light' ? 'github' : 'twilight'}
      name="editor"
      width={'100%'}
      height={'500px'}
      tabSize={2}
      value={value}
      onChange={_onChange}
      editorProps={{ $blockScrolling: true }}
      readOnly={options.readOnly || false}
    />
  )
}

export default EditorWidget
