import React from 'react'
import { useSelector } from 'react-redux'
import { Layout } from 'react-admin'
import { makeStyles } from '@material-ui/core'

import createTheme from '../../utils/theme'
import AppBar from './AppBar'
import LogoutButton from './LogoutButton'
import Menu from './Menu'
import Sidebar from './Sidebar'

const useStyles = makeStyles({
  '@global': {
    html: {
      width: '100%',
      height: '100%'
    },
    body: {
      height: '100%',
      width: '100%',
      padding: 0,
      margin: 0
    },
    '#root > div': {
      height: '100%'
    }
  },
  root: {
    width: '100%',
    height: '100%',
    '& > div': {
      height: '100%'
    }
  }
})

const CustomLayout = props => {
  const classes = useStyles()
  const isLight = useSelector(state => state.theme) === 'light'

  const theme = React.useMemo(() => createTheme(isLight), [isLight])

  return (
    <Layout
      {...props}
      classes={classes}
      appBar={AppBar}
      sidebar={Sidebar}
      menu={Menu}
      logout={<LogoutButton />}
      theme={theme}
    />
  )
}

export default CustomLayout
