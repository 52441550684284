import React, { useState } from 'react'
import {
  FunctionField,
  TextField,
  useMutation,
  useNotify,
  useRefresh
} from 'react-admin'
import { makeStyles } from '@material-ui/core'
import {
  Button,
  DateField,
  DeleteButton,
  ListActions,
  ListView
} from '../../components'
import OrgUserAccessKeyDialog from './OrgUserAccessKeyDialog'

const useStyles = makeStyles(theme => ({
  list: {
    paddingTop: 0
  }
}))

const getActions = onCreate => {
  const Actions = props => (
    <ListActions {...props}>
      <Button onClick={onCreate} label="+ Add Key" disableElevation />
    </ListActions>
  )
  return Actions
}

const OrgUserEditAccessKeysTab = ({ customerId, userId }) => {
  const classes = useStyles()
  const [key, setKey] = useState('')
  const [open, setOpen] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()
  const Actions = getActions(() => setOpen(true))

  const [save, { loading }] = useMutation()
  const onSave = data => {
    const payload = { description: data.description }
    if (data.apiKey !== '') {
      payload['key'] = data.apiKey
    }
    save(
      {
        type: 'create',
        resource: `users/${userId}/access_keys`,
        payload: { data: payload }
      },
      {
        onSuccess: ({ data: newRecord }) => {
          notify('ra.notification.created', 'info')
          setKey(newRecord.key)
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
          refresh()
        }
      }
    )
  }

  const closeDialog = () => {
    const created = key !== ''
    setKey('')
    setOpen(false)
    if (created) {
      refresh()
    }
  }

  return (
    <>
      <ListView
        className={classes.list}
        bulkActionButtons={false}
        basePath="/organization"
        component="div"
        hasCreate={false}
        hasEdit={false}
        hasList
        hasShow={false}
        actions={<Actions />}
        filter={{ userId: userId }}
        resource="user_access_keys"
        sort={{ field: 'id', order: 'ASC' }}
      >
        <TextField label="Prefix" source="prefix" sortable={false} />
        <TextField label="Description" source="description" />
        <DateField label="Created At" source="addedDatetime" />
        <FunctionField
          render={record => (
            <DeleteButton
              basePath={`organization/users/${userId}`}
              record={{ id: record.id, userId: userId }}
              resource="user_access_keys"
              redirect={false}
              confirmContent="Are you sure you want to delete this access key?"
            />
          )}
        />
      </ListView>
      <OrgUserAccessKeyDialog
        apiKey={key}
        loading={loading}
        open={open}
        onSave={onSave}
        onCancel={closeDialog}
      />
    </>
  )
}

export default OrgUserEditAccessKeysTab
