import React from 'react'
import { List, Datagrid, Pagination } from 'react-admin'
import { useHistory } from 'react-router-dom'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import classnames from 'classnames'
import SectionTitle from '../SectionTitle'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.text.primary
  },
  headerCell: {
    color: theme.palette.text.secondary,
    fontWeight: 'bold'
  }
}))

const CustomDataGrid = props => {
  const { children, ...rest } = props
  const classes = useStyles()
  const theme = useTheme()
  const scrollable = useMediaQuery(theme.breakpoints.down('sm'))
  if (scrollable) {
    return (
      <div style={{ width: '100vw', overflow: scrollable ? 'auto' : 'hidden' }}>
        <Datagrid {...rest} classes={{ headerCell: classes.headerCell }}>
          {children}
        </Datagrid>
      </div>
    )
  }
  return (
    <Datagrid {...rest} classes={{ headerCell: classes.headerCell }}>
      {children}
    </Datagrid>
  )
}

const ListView = props => {
  const { className, showBackBtn, children, actions, options, ...rest } = props
  const history = useHistory()
  const classes = useStyles()

  return (
    <div className={classnames(classes.root, className)}>
      {options && options.label && (
        <SectionTitle
          title={options.label}
          history={showBackBtn ? history : null}
        />
      )}

      <List
        {...rest}
        className={classes.list}
        exporter={false}
        actions={actions}
        pagination={<Pagination rowsPerPageOptions={[]} />}
      >
        <CustomDataGrid>{children}</CustomDataGrid>
      </List>
    </div>
  )
}

export default ListView
