import React from 'react'
import get from 'lodash/get'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  },
  gridItem: {
    marginBottom: theme.spacing(2)
  }
}))

const ObjectFieldTemplate = ({
  DescriptionField,
  description,
  TitleField,
  title,
  properties,
  required,
  uiSchema,
  idSchema
}) => {
  const classes = useStyles()
  const uiOptions = uiSchema['ui:options'] || {
    showTitle: true
  }

  return (
    <>
      {uiOptions.showTitle && (uiSchema['ui:title'] || title) && (
        <TitleField
          id={`${idSchema.$id}-title`}
          title={title}
          required={required}
        />
      )}
      {description && (
        <DescriptionField
          id={`${idSchema.$id}-description`}
          description={description}
        />
      )}
      <Grid container={true} spacing={2} className={classes.root}>
        {properties.map((element, index) => {
          let size = get(uiSchema, `${element['name']}.ui:options.gridSize`, 12)
          let rightOffset = get(
            uiSchema,
            `${element['name']}.ui:options.rightOffset`,
            0
          )
          return (
            <React.Fragment key={index}>
              <Grid item={true} xs={size} className={classes.gridItem}>
                {element.content}
              </Grid>
              {rightOffset > 0 && (
                <Grid item xs={rightOffset} className={classes.gridItem} />
              )}
            </React.Fragment>
          )
        })}
      </Grid>
    </>
  )
}

export default ObjectFieldTemplate
