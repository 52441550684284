import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import merge from 'lodash/merge'
import {
  FormWithRedirect,
  SaveButton,
  useMutation,
  useNotify,
  useQuery,
  useRedirect
} from 'react-admin'
import { makeStyles, Grid, Paper, Toolbar } from '@material-ui/core'
import Form from '@rjsf/material-ui'
import { Button, SectionTitle } from '../../components'
import {
  fields,
  widgets,
  ObjectFieldTemplate,
  ArrayFieldTemplate
} from './Forms'
import { getSchemas } from '../../utils/jsonSchemas'
import { useAuth } from '../../utils/auth'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const getRedirectPath = pathname => {
  let idx = pathname.indexOf('/services')
  return `${pathname.substring(0, idx)}/show?tab=1`
}

const transformErrors = errors => {
  return errors.map(error => {
    if (error.name === 'required') {
      error.message = 'Required'
    }
    return error
  })
}

const _canEdit = (pathname, auth) => {
  if (pathname.endsWith('/show')) {
    return false
  }
  return auth.canEditDeployments()
}

const DeploymentServiceEdit = ({ location, match }) => {
  const classes = useStyles()
  const deploymentId = match.params.id
  const deploymentServiceId = match.params.service_id
  const redirectPath = getRedirectPath(location.pathname)
  const notify = useNotify()
  const redirect = useRedirect()
  const submitButton = useRef(null)
  const [formData, setFormData] = useState({})
  const [depService, setDepService] = useState(undefined)
  const handleChange = (elm, event) => setFormData(elm.formData)
  const auth = useAuth()
  const canEdit = _canEdit(location.pathname, auth)

  const [save, { loading: saving }] = useMutation()

  const onSave = ({ formData }) => {
    //ADD HANDLER KEY TO REGISTERED TASKS

    let values = merge({}, formData)

    if (
      formData.runSettings &&
      formData.runSettings.registeredTasks &&
      formData.runSettings.registeredTasks.length > 0
    ) {
      const newTaskList = formData.runSettings.registeredTasks.map(task => ({
        handler: task
      }))
      const newFormData = {
        ...formData,
        runSettings: {
          ...formData.runSettings,
          registeredTasks: newTaskList
        }
      }
      const values = merge({}, newFormData)
    }
    const general = values.general
    delete values['general']
    const data = {
      name: general.name,
      values
    }
    save(
      {
        type: 'update',
        resource: `deployments/${deploymentId}/services`,
        payload: { id: depService.id, data }
      },
      {
        onSuccess: () => {
          console.log('data', data)
          notify('Element updated', 'info')
          redirect(redirectPath)
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  useQuery(
    {
      type: 'getOne',
      resource: `deployments/${deploymentId}/services`,
      payload: { id: deploymentServiceId }
    },
    {
      onSuccess: ({ data }) => {
        const formData = merge({}, data.values, {
          general: { name: data.name }
        })
        // CHECK IF THE REGISTERED TASKS ARE TYPE OF STRING
        // IF ITS AN OBJECT THEN DROP THE HANDLER KEY
        if (
          formData.runSettings &&
          formData.runSettings.registeredTasks &&
          typeof formData.runSettings.registeredTasks[0] === 'object' &&
          formData.runSettings.registeredTasks[0].constructor === Object
        ) {
          const newTaskList = formData.runSettings.registeredTasks.map(task => {
            if (
              task &&
              typeof task === 'object' &&
              task.constructor === Object
            ) {
              for (const [key, value] of Object.entries(task)) {
                return value
              }
            }
          })
          const newFormData = {
            ...formData,
            runSettings: {
              ...formData.runSettings,
              registeredTasks: newTaskList
            }
          }
          setFormData(newFormData)
        } else {
          setFormData(formData)
        }

        setDepService(data)
      },
      onFailure: () => {
        notify('ra.notification.item_doesnt_exist', 'warning')
        redirect(redirectPath)
      }
    }
  )
  if (!depService) {
    return null
  }

  const { schema, uiSchema } = getSchemas(depService.serviceSchema, 'edit')

  return (
    <div className={classes.root}>
      <SectionTitle
        title={
          canEdit
            ? `Edit Service: ${depService.name} | ${depService.niceId} | ${depService.id}`
            : `Service: ${depService.name} | ${depService.niceId} | ${depService.id}`
        }
      />
      <Paper className={classes.content}>
        <FormWithRedirect
          save={() => submitButton.current.click()}
          saving={saving}
          redirect={redirectPath}
          render={formProps => (
            <div>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={12}>
                  <Form
                    formData={formData}
                    schema={schema}
                    uiSchema={uiSchema}
                    ArrayFieldTemplate={ArrayFieldTemplate}
                    ObjectFieldTemplate={ObjectFieldTemplate}
                    fields={fields}
                    widgets={widgets}
                    showErrorList={false}
                    noHtml5Validate={true}
                    transformErrors={transformErrors}
                    onChange={handleChange}
                    onSubmit={onSave}
                  >
                    <button ref={submitButton} style={{ display: 'none' }} />
                  </Form>
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                {canEdit && (
                  <SaveButton
                    size="small"
                    saving={formProps.saving}
                    handleSubmitWithRedirect={
                      formProps.handleSubmitWithRedirect
                    }
                  />
                )}
                <Button
                  className={classes.button}
                  component={Link}
                  to={redirectPath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </div>
          )}
        />
      </Paper>
    </div>
  )
}

export default DeploymentServiceEdit
