import { createMuiTheme } from '@material-ui/core/styles'
import { ArrowDropDown } from '@material-ui/icons'

const createTheme = isLight => {
  const baseTheme = createMuiTheme({
    palette: {
      type: isLight ? 'light' : 'dark',
      common: { black: '#000', white: '#fff' },
      background: {
        paper: isLight ? '#fff' : '#31353B',
        default: isLight ? '#fafafa' : '#1A1E26',
        level2: '#333'
      },
      primary: {
        light: isLight ? 'rgba(86, 71, 151, 1)' : '#8df2ff',
        main: isLight ? 'rgba(38, 31, 104, 1)' : '#52bffa',
        dark: isLight ? 'rgba(0, 0, 61, 1)' : '#008fc7',
        contrastText: '#fff'
      },
      secondary: {
        light: 'rgba(142, 255, 255, 1)',
        main: 'rgba(82, 205, 250, 1)',
        dark: 'rgba(0, 156, 199, 1)',
        contrastText: '#fff'
      },
      error: {
        light: '#e57373',
        main: '#f44336',
        dark: '#d32f2f',
        contrastText: '#fff'
      },
      action: {
        selected: isLight ? 'rgba(38, 31, 104, 1)' : '#31353B'
      }
    }
  })

  const theme = createMuiTheme({
    palette: baseTheme.palette,
    typography: {
      button: {
        textTransform: 'none'
      },
      fontFamily: ['Lato', 'sans-serif']
    },
    props: {
      MuiTableSortLabel: {
        IconComponent: ArrowDropDown
      },
      MuiButton: {
        disableElevation: true
      },
      MuiTextField: {
        InputLabelProps: {
          shrink: true
        }
      }
    },
    overrides: {
      MuiAppBar: {
        root: {
          boxShadow: 'none',
          backgroundColor: isLight
            ? 'white !important'
            : baseTheme.palette.background.level2,
          borderBottom: `1px solid ${baseTheme.palette.divider}`,
          color: `${baseTheme.palette.primary.main} !important`
        }
      },
      MuiDrawer: {
        docked: {
          backgroundColor: isLight ? 'white !important' : 'inherit'
        }
      },
      RaLayout: {
        root: {
          overflow: 'hidden'
        },
        contentWithSidebar: {
          overflow: 'hidden'
        },
        content: {
          overflow: 'auto',
          paddingBottom: 70,
          [baseTheme.breakpoints.up('xs')]: {
            paddingLeft: baseTheme.spacing(3)
          },
          [baseTheme.breakpoints.down('sm')]: {
            padding: 0
          }
        }
      },
      RaMenuItemLink: {
        root: {
          paddingTop: baseTheme.spacing(1.5),
          paddingBottom: baseTheme.spacing(1.5)
        },
        active: {
          background: baseTheme.palette.action.selected,
          color: baseTheme.palette.primary.contrastText,
          '& $icon': {
            color: baseTheme.palette.primary.contrastText
          },
          '&:hover': {
            background: baseTheme.palette.action.selected
          }
        }
      },
      RaConfirm: {
        iconPaddingStyle: {
          display: 'none'
        },
        confirmPrimary: {
          color: baseTheme.palette.primary.contrastText,
          backgroundColor: baseTheme.palette.primary.main,
          '&:hover': {
            backgroundColor: baseTheme.palette.primary.dark,
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              backgroundColor: baseTheme.palette.primary.main
            }
          }
        }
      }
    }
  })

  return theme
}
export default createTheme

// export default theme
