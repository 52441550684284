import React from 'react'
import PropTypes from 'prop-types'
import { FunctionField } from 'react-admin'
import moment from 'moment'

const DateField = props => {
  const { dateFormat, label, source, ...rest } = props
  return (
    <FunctionField
      {...rest}
      label={label || source}
      render={record =>
        record[source] ? moment(record[source]).format(dateFormat) : ''
      }
    />
  )
}

DateField.propTypes = {
  dateFormat: PropTypes.string,
  label: PropTypes.string,
  source: PropTypes.string.isRequired
}

DateField.defaultProps = {
  dateFormat: 'YYYY-MM-DD hh:mm:ss A',
  label: undefined
}

export default DateField
