import React from 'react'
import PropTypes from 'prop-types'

import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles({
  button: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  label: {
    paddingLeft: '0.5em'
  },
  labelRightIcon: {
    paddingRight: '0.5em'
  }
})

const sanitizeButtonRestProps = ({
  basePath,
  handleSubmit,
  handleSubmitWithRedirect,
  invalid,
  onSave,
  pristine,
  record,
  redirect,
  resource,
  saving,
  submitOnEnter,
  undoable,
  ...rest
}) => rest

const Button = props => {
  const {
    alignIcon,
    color,
    disabled,
    Icon,
    label,
    size,
    tooltip,
    ...rest
  } = props
  const classes = useStyles()
  const restProps = sanitizeButtonRestProps(rest)

  return (
    <Tooltip title={tooltip}>
      {label ? (
        <MuiButton
          className={classes.button}
          color={color}
          disabled={disabled}
          size={size}
          aria-label={label}
          {...restProps}
        >
          {Icon && alignIcon === 'left' && <Icon fontSize="small" />}
          <span
            className={classnames({
              [classes.label]: alignIcon === 'left',
              [classes.labelRightIcon]: alignIcon !== 'left'
            })}
          >
            {label}
          </span>
          {Icon && alignIcon === 'right' && <Icon fontSize="small" />}
        </MuiButton>
      ) : (
        <MuiIconButton
          color={color}
          disabled={disabled}
          size={size}
          {...restProps}
        >
          <Icon fontSize="small" />
        </MuiIconButton>
      )}
    </Tooltip>
  )
}

Button.propTypes = {
  alignIcon: PropTypes.oneOf(['left', 'right']),
  color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
  disabled: PropTypes.bool,
  label: PropTypes.string,
  Icon: PropTypes.object,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  tooltip: PropTypes.string
}

Button.defaultProps = {
  alignIcon: 'left',
  disabled: false,
  color: 'primary',
  onClick: () => {},
  size: 'small',
  tooltip: ''
}

export default Button
