import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import yaml from 'js-yaml'
import {
  FormWithRedirect,
  TextInput,
  SaveButton,
  useMutation,
  useNotify,
  useRedirect,
  required
} from 'react-admin'
import { makeStyles, Grid, Paper, Toolbar } from '@material-ui/core'
import { AceEditorInput, Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const _getRedirectPath = pathname => {
  let idx = pathname.indexOf('/pipelines')
  return `${pathname.substring(0, idx)}/show?tab=2`
}

const DeploymentPipelineCreate = ({ location, match }) => {
  const classes = useStyles()
  const [invalidConfig, setInvalidConfig] = useState(false)
  const deploymentId = match.params.id
  const redirectPath = _getRedirectPath(location.pathname)
  const notify = useNotify()
  const redirect = useRedirect()

  const [save, { loading }] = useMutation()
  const onSave = data => {
    data.config = yaml.safeLoad(data.config)
    data.schemaVersion = 'latest'
    data.sermosPipelineId = data.name.toLowerCase().replace(' ', '-')
    save(
      {
        type: 'create',
        resource: `deployments/${deploymentId}/pipelines`,
        payload: { data }
      },
      {
        onSuccess: () => {
          notify('ra.notification.created', 'info')
          redirect(redirectPath)
        },
        onFailure: error => {
          if (error.status !== 422) {
            notify(
              typeof error === 'string'
                ? error
                : error.message || 'ra.notification.http_error',
              'warning'
            )
          } else {
            if (error.body.config !== undefined) {
              setInvalidConfig(true)
            }
          }
        }
      }
    )
  }

  return (
    <div className={classes.root}>
      <SectionTitle title="Create New Pipeline" />
      <Paper className={classes.content}>
        <FormWithRedirect
          save={onSave}
          saving={loading}
          redirect={redirectPath}
          render={formProps => (
            <form>
              <Grid container className={classes.form} spacing={2}>
                <Grid item xs={12} lg={6}>
                  <TextInput
                    source="name"
                    label="Name"
                    variant="standard"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    source="description"
                    label="Description"
                    variant="standard"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <AceEditorInput
                    source="config"
                    label="Config"
                    invalidValue={invalidConfig}
                    validate={[required()]}
                  />
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                <SaveButton
                  size="small"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <Button
                  className={classes.button}
                  component={Link}
                  to={redirectPath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default DeploymentPipelineCreate
