import React, { useEffect, useState } from 'react'
import {
  FunctionField,
  TextField,
  useDataProvider,
  useMutation,
  useNotify,
  useRefresh
} from 'react-admin'
import {
  Avatar,
  Divider,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Paper,
  TextField as MuiTextField,
  makeStyles
} from '@material-ui/core'
import { Search, Add, AccountCircle } from '@material-ui/icons'

import { Autocomplete } from '@material-ui/lab'
import debounce from 'lodash/debounce'
import { DeleteButton, ListView } from '../../components'

const useStyles = makeStyles(theme => ({
  search: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100% - 10px)'
    },
    [theme.breakpoints.up('md')]: {
      width: 450
    }
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  list: {
    paddingTop: theme.spacing(3),
    '& .MuiToolbar-root': {
      display: 'none'
    }
  }
}))

const PopperContent = ({ children, ...rest }) => (
  <Paper variant="outlined" {...rest}>
    {children}
  </Paper>
)

const DeploymentEditUsersTab = ({ id, organizationId }) => {
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const [selectedUser, setSelectedUser] = useState(null)
  const [options, setOptions] = useState([])
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const [save] = useMutation()
  const onAddUser = () => {
    if (!selectedUser) {
      return
    }

    save(
      {
        type: 'create',
        resource: `deployments/${id}/users`,
        payload: { data: { userId: selectedUser.id } }
      },
      {
        onSuccess: () => {
          notify('ra.notification.created', 'info')
          refresh()
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  useEffect(() => {
    if (searchValue === '') {
      setOptions([])
      return undefined
    }
    dataProvider
      .getList('org_users', {
        pagination: { page: 0, perPage: 10 },
        sort: { field: 'name', order: 'ASC' },
        filter: { orgId: organizationId, search: searchValue }
      })
      .then(({ data }) => {
        const options = data.map(user => ({
          id: user.id,
          name: user.name || user.email,
          email: user.email
        }))
        setOptions(options)
      })
      .catch(error => {
        notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning'
        )
      })
  }, [searchValue, dataProvider, notify, organizationId])

  return (
    <div>
      <Paper component="form" className={classes.search} variant="outlined">
        <IconButton className={classes.iconButton} aria-label="menu">
          <Search />
        </IconButton>
        <Autocomplete
          className={classes.input}
          getOptionLabel={option => option.name}
          options={options}
          value={selectedUser}
          onChange={(event, value) => setSelectedUser(value)}
          onInputChange={debounce((event, value) => setSearchValue(value), 200)}
          getOptionSelected={(option, value) => option.name === value.name}
          renderInput={({ InputProps, ...rest }) => {
            InputProps['disableUnderline'] = true
            return (
              <MuiTextField
                {...rest}
                InputProps={InputProps}
                placeholder="Search Users"
              />
            )
          }}
          renderOption={option => (
            <>
              <ListItemAvatar>
                <Avatar>
                  <AccountCircle />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={option.name} secondary={option.email} />
            </>
          )}
          PaperComponent={PopperContent}
        />
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          className={classes.iconButton}
          aria-label="directions"
          onClick={onAddUser}
        >
          <Add />
        </IconButton>
      </Paper>
      <ListView
        className={classes.list}
        bulkActionButtons={false}
        basePath="/deployments"
        component="div"
        hasCreate={false}
        hasEdit={false}
        hasList
        hasShow={false}
        filter={{ deploymentId: id }}
        resource="deployment_users"
        sort={{ field: 'name', order: 'ASC' }}
      >
        <FunctionField
          label="Name"
          render={record => (record.name ? record.name : record.email)}
        />
        <TextField label="Username" source="email" />
        <TextField label="Type" source="type" />
        <TextField label="Role" source="role" />
        <FunctionField
          render={record => (
            <DeleteButton
              basePath={`deployments/${id}`}
              record={{ id: record.id, deploymentId: id }}
              resource="deployment_users"
              redirect={false}
              confirmContent="Are you sure you want to remove this user from the deployment?"
            />
          )}
        />
      </ListView>
    </div>
  )
}

export default DeploymentEditUsersTab
