import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import {
  FormWithRedirect,
  TextInput,
  Toolbar,
  SaveButton,
  useMutation,
  useNotify
} from 'react-admin'
import { Box, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  form: {
    [theme.breakpoints.up('md')]: {
      width: '50%'
    }
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const Spacer = () => <span style={{ flex: 1 }} />

const UserAccountCredentialsEdit = () => {
  const classes = useStyles()
  const [match, setPasswordsMatch] = useState(true)
  const [values, setValues] = useState({
    password: '',
    confirmPassword: ''
  })
  const user = useSelector(state => state.user)
  const notify = useNotify()

  const onChange = key => e => {
    const newValues = Object.assign({}, values)
    newValues[key] = e.target.value
    setValues(newValues)
  }

  const [save, { loading }] = useMutation()
  const onSave = ({ password, confirmPassword }) => {
    let _password = password.trim()
    let _confirmPassword = confirmPassword.trim()
    if (_password === '') {
      return
    } else if (_password !== _confirmPassword) {
      setPasswordsMatch(false)
      return
    }

    save(
      {
        type: 'update',
        resource: 'users',
        payload: { id: user.id, data: { password: _password } }
      },
      {
        onSuccess: () => {
          notify('Password updated', 'info')
          setPasswordsMatch(true)
          setValues({
            password: '',
            confirmPassword: ''
          })
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  return (
    <div className={classes.root}>
      <FormWithRedirect
        save={onSave}
        saving={loading}
        redirect={'/myaccount'}
        render={formProps => (
          <form>
            <Box className={classes.form} m={2}>
              <TextInput
                label="Password"
                source="password"
                variant="standard"
                type="password"
                options={{ value: values['password'] }}
                onChange={onChange('password')}
                fullWidth
              />
              <TextInput
                label="Confirm password"
                source="confirmPassword"
                variant="standard"
                type="password"
                options={{ value: values['confirmPassword'] }}
                onChange={onChange('confirmPassword')}
                fullWidth
                error={!match}
                helperText={match ? '' : "Passwords don't match"}
              />
            </Box>
            <Toolbar className={classes.toolbar}>
              <Spacer />
              <SaveButton
                size="small"
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            </Toolbar>
          </form>
        )}
      />
    </div>
  )
}

export default UserAccountCredentialsEdit
