import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import { Confirm } from 'react-admin'

const ButtonWithConfirm = props => {
  const {
    className,
    confirmTitle,
    confirmContent,
    loading,
    onConfirm,
    ...rest
  } = props
  const [open, setOpen] = useState(false)
  const handleClick = e => {
    e.stopPropagation()
    setOpen(true)
  }
  const handleConfirm = e => {
    e.stopPropagation()
    onConfirm()
  }
  const handleClose = (e = undefined) => {
    if (e) e.stopPropagation()
    setOpen(false)
  }

  useEffect(() => {
    if (!loading) {
      setOpen(false)
    }
  }, [loading])

  return (
    <>
      <Button {...rest} className={className} onClick={handleClick} />
      <Confirm
        isOpen={open || loading}
        loading={loading}
        title={confirmTitle}
        content={confirmContent}
        onConfirm={handleConfirm}
        onClose={handleClose}
        confirmColor="primary"
      />
    </>
  )
}

ButtonWithConfirm.propTypes = {
  className: PropTypes.string,
  confirmTitle: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func
}

ButtonWithConfirm.defaultProps = {
  confirmTitle: 'Confirm',
  confirmContent: 'Are you sure?',
  loading: false,
  onConfirm: () => {}
}

export default ButtonWithConfirm
