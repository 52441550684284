import React from 'react'

import { Switch } from '@material-ui/core'

const SwitchWidget = ({ id, value, onChange, onBlur, onFocus }) => {
  const _onChange = e => onChange(e.target.checked)
  const _onBlur = ({ target: { value } }) => onBlur(id, value)
  const _onFocus = ({ target: { value } }) => onFocus(id, value)
  return (
    <Switch
      color="primary"
      onChange={_onChange}
      onFocus={_onFocus}
      onBlur={_onBlur}
      checked={value}
      fullWidth={false}
    />
  )
}

export default SwitchWidget
