import React from 'react'
import { useDispatch } from 'react-redux'
import { useAuth } from '../../utils/auth'

import {
  BooleanInput,
  FormWithRedirect,
  SaveButton,
  TextInput,
  useNotify,
  useMutation,
  required
} from 'react-admin'

import { Grid, Toolbar, makeStyles } from '@material-ui/core'

import { mergeUserCustomers } from '../../redux/user'

const useStyles = makeStyles(theme => ({
  toolbar: {
    marginTop: theme.spacing(5),
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const GeneralOrgTab = ({ org, updateStore }) => {
  const classes = useStyles()
  const notify = useNotify()
  const dispatch = useDispatch()
  const auth = useAuth()

  const [update, { loading }] = useMutation()
  const save = ({ id, name, enableSupport }) =>
    update(
      {
        type: 'update',
        resource: 'organizations',
        payload: { id, data: { name, enableSupport } }
      },
      {
        onSuccess: () => {
          notify('Element updated', 'info')
          if (updateStore) {
            dispatch(mergeUserCustomers({ id: org.id, name: name }))
          }
        },
        onFailure: error =>
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
      }
    )

  return (
    <FormWithRedirect
      record={org}
      save={save}
      saving={loading}
      rediect={false}
      render={formProps => (
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <TextInput
                source="id"
                label="Organization ID"
                variant="standard"
                disabled
                fullWidth
              />
              <TextInput
                source="name"
                label="Name"
                variant="standard"
                validate={[required()]}
                fullWidth
              />
              {auth.isSysAdmin() && (
                <BooleanInput source="enableSupport" label="Enable Support" />
              )}
            </Grid>
          </Grid>
          <Toolbar className={classes.toolbar}>
            <span style={{ flex: 1 }} />
            {auth.canEditOrg() && (
              <SaveButton
                size="small"
                saving={formProps.saving}
                redirect={false}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
              />
            )}
          </Toolbar>
        </form>
      )}
    />
  )
}

export default GeneralOrgTab
