import React from 'react'
import { useStore } from 'react-redux'
import { Link } from 'react-router-dom'
import { makeStyles, Grid, Paper, Toolbar } from '@material-ui/core'
import {
  FormWithRedirect,
  FormDataConsumer,
  SelectInput,
  TextInput,
  SaveButton,
  RadioButtonGroupInput,
  useMutation,
  useNotify,
  useRedirect,
  required
} from 'react-admin'
import { Button, SectionTitle } from '../../components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  form: {
    padding: theme.spacing(2)
  },
  toolbar: {
    backgroundColor: 'inherit',
    borderTop: `1px solid ${theme.palette.divider}`
  },
  button: {
    marginLeft: theme.spacing(1)
  }
}))

const _getRedirectPath = pathname => {
  let idx = pathname.indexOf('/users')
  return pathname.substring(0, idx)
}

const UserCreate = ({ location, match }) => {
  const classes = useStyles()
  const { customer } = useStore().getState()
  const customerId = match.params.id || customer
  const notify = useNotify()
  const redirect = useRedirect()
  const redirectPath = _getRedirectPath(location.pathname)

  const [save, { loading }] = useMutation()
  const onSave = data => {
    save(
      {
        type: 'create',
        resource: `organizations/${customerId}/users`,
        payload: { data }
      },
      {
        onSuccess: () => {
          notify('ra.notification.created', 'info')
          redirect(redirectPath)
        },
        onFailure: error => {
          notify(
            typeof error === 'string'
              ? error
              : error.message || 'ra.notification.http_error',
            'warning'
          )
        }
      }
    )
  }

  return (
    <div className={classes.root}>
      <SectionTitle title="Add User" />
      <Paper className={classes.content}>
        <FormWithRedirect
          save={onSave}
          saving={loading}
          redirect={redirectPath}
          render={formProps => (
            <form>
              <Grid container className={classes.form}>
                <Grid item xs={12} lg={6}>
                  <RadioButtonGroupInput
                    source="type"
                    label="User type"
                    choices={[
                      { id: 'console', name: 'Console user' },
                      { id: 'programmatic', name: 'Programmatic user' }
                    ]}
                    validate={[required()]}
                  />
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'console' && (
                        <TextInput
                          source="email"
                          label="Email"
                          type="email"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type === 'programmatic' && (
                        <TextInput
                          source="name"
                          label="Name"
                          variant="standard"
                          validate={[required()]}
                          fullWidth
                        />
                      )
                    }
                  </FormDataConsumer>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.type !== undefined && (
                        <SelectInput
                          source="role"
                          label="Role"
                          variant="standard"
                          choices={[
                            { id: 'Admin', name: 'Administrator' },
                            { id: 'Editor', name: 'Editor' },
                            { id: 'Viewer', name: 'Viewer' }
                          ]}
                          validate={[required()]}
                        />
                      )
                    }
                  </FormDataConsumer>
                </Grid>
              </Grid>
              <Toolbar className={classes.toolbar}>
                <span style={{ flex: 1 }} />
                <SaveButton
                  size="small"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <Button
                  className={classes.button}
                  component={Link}
                  to={redirectPath}
                  label="Cancel"
                  variant="contained"
                  color="default"
                />
              </Toolbar>
            </form>
          )}
        />
      </Paper>
    </div>
  )
}

export default UserCreate
