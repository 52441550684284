import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { FormControl, MenuItem, Select, makeStyles } from '@material-ui/core'
import { PentagonOutline } from 'mdi-material-ui'
import { setCustomer } from '../../redux/customer'

const useStyles = makeStyles(theme => ({
  icon: {
    paddingRight: theme.spacing(1)
  },
  select: {
    minWidth: 150
  }
}))

const CustomerMenu = ({ customer, user }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const history = useHistory()
  const onChange = event => {
    dispatch(setCustomer(event.target.value))
    history.replace('/')
  }

  return (
    <>
      <PentagonOutline className={classes.icon} />
      <FormControl className={classes.select}>
        <Select id="org" value={customer} onChange={onChange} disableUnderline>
          {user.customers.map(c => (
            <MenuItem key={c.id} value={c.id}>
              {c.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  )
}

const mapStateToProps = ({ customer, user }) => ({ customer, user })

export default connect(mapStateToProps, {})(CustomerMenu)
